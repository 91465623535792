import React, { Component } from 'react'
import { Picker } from 'antd-mobile';
import options from './component/cascader-address-option'

const defaultFormat = (values) => values.join('')

export default class P extends Component<any, any> {

  static defaultProps = {
    data: options
  }

  render() {
    return (
      <Picker
        {...this.props as any}
      >
        <Wrapper content={this.props.children as any}>
        </Wrapper>
      </ Picker>
    );
  }
}



class Wrapper extends React.Component<any, any> {

  render() {
    // 需要调用onClick方法弹出地址组件
    const { onClick, content } = this.props as any
    return (
      <div onClick={onClick || undefined}>
        {content}
      </div>
    )
  }
}
