/**
 * 微信分享
 * 微信端
 */
import { chooseAnduploadImage } from './wechat';

/**
 * @param count 数量
 * @param sizeType 可以指定是原图还是压缩图，默认二者都有
 * @param sourceType 可以指定来源是相册还是相机，默认二者都有
 */
//count = 1, sizeType = ['original', 'compressed'], sourceType = ['album', 'camera']
export const uploadImg = (
  count = 1,
  sizeType = ['original', 'compressed'],
  sourceType = ['album', 'camera']
) => {
  return chooseAnduploadImage(count, sizeType, sourceType);
};
