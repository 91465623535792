import React from 'react'
import {msg} from 'plume2'
import { Link } from 'react-router-dom'
import {history} from 'wmkit'
import './css/style.css'

const tips = require('./img/tips.png')

export default class StoreClose extends React.Component<any, any>{

  componentWillMount() {
    msg.on('storeCloseVisible', this.changeVisible)
  }

  componentWillUnmount() {
    msg.off('storeCloseVisible', this.changeVisible)
  }

  constructor(props){
    super(props);
    this.state = {
      //店铺关闭弹框可见性
      storeCloseVisible: false
    }
  }

  render() {
    const { storeCloseVisible:visible } = this.state
    return (
      <div>
        {
          visible &&
          <div className="mask">
            <div className="anyway-center">
              <div className="store-dialog">
                <img src={tips} alt="" />
                <h2>店铺异常</h2>
                <p>该店铺不存在或已关闭!</p>
                <a onClick={()=>{
                  msg.emit('storeCloseVisible',false)
                  history.push('/')
                }}>
                  去商城首页
                </a>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }

  changeVisible = (storeCloseVisible) => {
    this.setState({storeCloseVisible:storeCloseVisible})
  }
}