import React, { Component } from 'react';
import { noop } from 'wmkit';

export interface ConfirmProps {
  title?: String;
  text: any;
  //点击确认回调函数
  confirmCb?: () => void;
  //点击取消回调函数
  cancel?: () => void;
  //关闭弹窗
  close?: () => void;
  //确认按钮文案
  okBtn?: string;
  //取消按钮文案
  cancelBtn?: string;
  //是否可以点击蒙层关闭弹窗
  maskClose?: boolean;
}

export default class Confirm extends Component<ConfirmProps, any> {
  static defaultProps = {
    title: '',
    text: '',
    close: noop,
    confirmCb: noop,
    cancel: noop,
    okBtn: '',
    cancelBtn: '',
    maskClose: true
  };

  render() {
    const {
      title,
      text,
      confirmCb,
      cancel,
      close,
      okBtn,
      cancelBtn,
      maskClose
    } = this.props;
    return (
      <div>
        <div className="mask" onClick={maskClose ? close : null} />
        <div className="poper-confirm">
          <div className="confirm-msg">
            <h1>{title}</h1>
            <h2 className="t-grayer"> {text}</h2>
          </div>
          <div className="confirm-opt">
            {cancelBtn ? (
              <div
                className="cancel"
                onClick={() => {
                  cancel && cancel();
                  close();
                }}
              >
                {cancelBtn}
              </div>
            ) : null}
            {okBtn ? (
              <div
                className="sure"
                onClick={() => {
                  let res;
                  if (confirmCb) {
                    res = confirmCb();
                  }
                  if (res != false) close();
                }}
              >
                {okBtn}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
