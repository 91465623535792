import React from 'react';
import { msg } from 'plume2';
import './css/style.css';

const tips = require('./img/tips.png');

/**
 * 我的分销员禁用弹出
 */
export default class BStoreClose extends React.Component<any, any> {
  componentWillMount() {
    msg.on('bStoreCloseVisible', this.changeVisible);
  }

  componentWillUnmount() {
    msg.off('bStoreCloseVisible', this.changeVisible);
  }

  constructor(props) {
    super(props);
    this.state = {
      //我的分销员禁用弹框可见性
      bStoreCloseVisible: false,
      //禁用原因
      reason: ''
    };
  }

  render() {
    const { bStoreCloseVisible: visible, reason } = this.state;
    return (
      <div>
        {visible && (
          <div className="mask">
            <div className="anyway-center store-dialog-box">
              <div className="b-store-dialog">
                <img src={tips} alt="" />
                <h2>您的店铺已被禁用</h2>
                <p>
                  禁用状态无法享受分销权益，不可推广商品赚返利，且不可管理店铺;
                </p>
                <p className="reason">禁用原因：{reason}</p>
              </div>
              <a
                className="store-close"
                onClick={() => {
                  msg.emit('bStoreCloseVisible', {
                    visible: false,
                    reason: reason
                  });
                }}
              >
                <i className="iconfont icon-Close" />
              </a>
            </div>
          </div>
        )}
      </div>
    );
  }

  changeVisible = ({ visible, reason }) => {
    this.setState({
      bStoreCloseVisible: visible,
      reason: reason
    });
  };
}
