import React from 'react'
import noop from '../noop'

type Handler = () => void

interface IProps {
  isShowLoadingMore?: boolean;
  onNext?: Handler;
  onPrev?: Handler;
}

interface IState {
  isLoadingMore: boolean;
  isShowPageAction: boolean;
  chunkPageNum: number;
}

export default class LoadingMore extends React.Component<any, any> {
  props: IProps
  state: IState

  static defaultProps = {
    isShowLoadingMore: true,
    onNext: noop,
    onPrev: noop
  };

  constructor(props) {
    super(props)
    this.state = {
      //是否显示加载更多
      isLoadingMore: false,
      //显示上一页，下一页
      isShowPageAction: false,
      //当前的大分页的页码
      chunkPageNum: 0
    }
  }

  shouldComponentUpdate(nextProps: IProps, nextState: IState) {
    return (
      nextProps.isShowLoadingMore != this.props.isShowLoadingMore
      || nextState.chunkPageNum != this.state.chunkPageNum
      || nextState.isLoadingMore != this.state.isLoadingMore
      || nextState.isShowPageAction != this.state.isShowPageAction
    )
  }

  render() {
    //如果不显示加载更多
    if (!this.props.isShowLoadingMore) {
      return null
    }

    const { isLoadingMore, isShowPageAction, chunkPageNum } = this.state

    const style = {
      'margin-top': '10px',
      'margin-bottom': '10px',
      display: 'flex',
      'justify-content': 'center',
      'align-items': 'center',
      'color': 'red',
      'font-size': '14px',
      background: '#fff'
    }

    const pageActionStyle = {
      'justify-content': 'center'
    }

    const aStyle = {
      color: '#999',
      'font-size': '12px',
      border: '1px solid #ccc',
      'border-radius': '5px',
      'text-decoration': 'none',
      padding: '5px 10px',
      margin: '0 5px'
    }

    //如果不显示
    if (!isLoadingMore) {
      return <div />;
    }

    //显示chunk action
    if (isShowPageAction) {
      return (
        <div style={Object.assign(style, pageActionStyle)}>
          {chunkPageNum > 1 ? <a style={aStyle} href={'javascript:;'} onClick={this.props.onPrev}>上一页</a> : null}
          <a style={aStyle} href={'javascript:;'} onClick={this.props.onNext}>下一页</a>
        </div>
      )
    }

    //显示加载更多
    return (
      <div className='loading-inset'>
        {/*<span>加载更多...</span>*/}
      </div>
    )
  }

  setting = ({ isLoadingMore, isShowPageAction, chunkPageNum }) => {
    this.setState({
      isLoadingMore,
      chunkPageNum,
      isShowPageAction
    })
  }
}
