import EXIF from 'exif-js'

/**
 * 压缩照片，压缩时会旋转照片到正位
 * @param base64Src 
 * @param quality 
 */

export default function (base64Src, quality): Promise<string> {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas') as HTMLCanvasElement;
    const context = canvas.getContext('2d');
    let image = new Image();
    image.src = base64Src;

    image.onload = () => {
      const orientation = EXIF.readFromBinaryFile(base64ToArrayBuffer(base64Src)).Orientation || 1;

      const { width, height, matrix } = getTransform(image, orientation);
      canvas.width = width;
      canvas.height = height;

      context.save();
      context.transform(matrix[0], matrix[1], matrix[2], matrix[3], matrix[4], matrix[5]);
      context.drawImage(image, 0, 0);

      let main_type = 'image/jpeg';
      let newData = canvas.toDataURL(main_type, quality / 100);

      context.restore();
      image.remove();

      resolve(newData)
    }
  })
}

const base64ToArrayBuffer = (base64, contentType?) => {
  contentType = contentType || base64.match(/^data\:([^\;]+)\;base64,/mi)[1] || ''; // e.g. 'data:image/jpeg;base64,...' => 'image/jpeg'
  base64 = base64.replace(/^data\:([^\;]+)\;base64,/gmi, '');
  var binary = atob(base64);
  var len = binary.length;
  var buffer = new ArrayBuffer(len);
  var view = new Uint8Array(buffer);
  for (var i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i);
  }
  return buffer;
}


/**
  * 旋转图片 
  */
const getTransform = (image, orientation): { width: number, height: number, matrix: Array<number> } => {
  const { width, height } = image;

  switch (orientation) {
    case 1:
      // default
      return {
        width, height,
        matrix: [1, 0,
          0, 1,
          0, 0]
      };

    case 2:
      // horizontal flip
      return {
        width, height,
        matrix: [-1, 0,
          0, 1,
          width, 0]
      };

    case 3:
      // 180° rotated
      return {
        width, height,
        matrix: [-1, 0,
          0, -1,
          width, height]
      };

    case 4:
      // vertical flip
      return {
        width, height,
        matrix: [1, 0,
          0, -1,
          0, height]
      };

    case 5:
      // vertical flip + -90° rotated
      return {
        width: height,
        height: width,
        matrix: [0, 1,
          1, 0,
          0, 0]
      };

    case 6:
      // -90° rotated
      return {
        width: height,
        height: width,
        matrix: [0, 1,
          -1, 0,
          height, 0]
      };

    case 7:
      // horizontal flip + -90° rotate
      return {
        width: height,
        height: width,
        matrix: [0, -1,
          -1, 0,
          height, width]
      };

    case 8:
      // 90° rotated
      return {
        width: height,
        height: width,
        matrix: [0, -1,
          1, 0,
          0, width]
      };
  }
}
