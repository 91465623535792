import React, { Component } from 'react';
import { fromJS, List } from 'immutable';
import { IList, IMap } from 'typings/globalType';

interface MarketingLabelProps {
  marketingLabels: IList;
  couponLabels?: IList;
  grouponLabel?: IMap;
}

// 排序规则 -- 满减-0、满折-1、满赠-2、优惠券-3 、拼团-4
const _SORT = [0, 1, 2, 3, 4,5];
const _TYPE = {
  '0': { text: '满减' },
  '1': { text: '满折' },
  '2': { text: '满赠' },
  '3': { text: '券' },
  '4': { text: '拼' },
  '5': { text: '秒杀' }
};

export default class MarketingLabel extends Component<
  MarketingLabelProps,
  any
> {
  render() {
    const { marketingLabels, couponLabels, grouponLabel } = this.props;

    let mLabels = marketingLabels;
    if (couponLabels && couponLabels.size > 0) {
      couponLabels.toJS().forEach((item) => {
        mLabels = mLabels.push(
          fromJS({
            marketingType: 3,
            marketingId: `${item.couponActivityId}-${item.couponInfoId}`,
            marketingDesc: item.couponDesc
          })
        );
      });
    }
    if (grouponLabel) {
      mLabels = mLabels.push(
        fromJS({
          marketingType: 4
        })
      );
    }
    const labels = fromJS(mLabels || []);
    const sortedLabels = this.renderSort(labels);
    return (
      <span className="tag-label-box">
        {sortedLabels.toJS().map((label, index) => {
          return (
            <div key={index} className="tag-label">
              {_TYPE[label.marketingType].text}
            </div>
          );
        })}
      </span>
    );
  }

  renderSort = (marketingLabels: IList) => {
    let array = List();
    if (marketingLabels && marketingLabels.size > 0) {
      let labelMap = marketingLabels.groupBy((label) =>
        label.get('marketingType')
      );
      _SORT.forEach((type) => {
        if (labelMap.has(type)) {
          array = array.push(labelMap.get(type).get(0));
        }
      });
    }
    return array;
  };
}
