import React from 'react';
import { Relax } from 'plume2';
import { noop } from 'wmkit';

@Relax
export default class EnterpriseAgreement extends React.Component<any, any> {
  props: {
    relaxProps?: {
      toggleShowEnterpriseAgreement: Function;
      registerEnterpriseContent: string;
    };
  };

  static relaxProps = {
    toggleShowEnterpriseAgreement: noop,
    registerEnterpriseContent: 'registerEnterpriseContent'
  };

  render() {
    const { toggleShowEnterpriseAgreement, registerEnterpriseContent } = this.props.relaxProps;
    return (
      <div className="agree-content">
        <a
          href="javascript:void(0);"
          className="closeBox"
          onClick={() => toggleShowEnterpriseAgreement()}
        >
          <i className="iconfont icon-jiantou" />
        </a>
        <div
          className="agree-box"
          dangerouslySetInnerHTML={{ __html: registerEnterpriseContent }}
        />
      </div>
    );
  }
}
