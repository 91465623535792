(function (window) {
  let wechatConfig = window['wechatConfig'];
  if (wechatConfig && wechatConfig.appId) {
    wechatConfig.jsApiList = [
      'checkJsApi', 'chooseImage', 'uploadImage', 'onMenuShareTimeline',
      'onMenuShareAppMessage', 'onMenuShareQQ', 'onMenuShareWeibo',
      'hideOptionMenu', 'showOptionMenu', 'closeWindow',
      'openLocation', 'getLocation'
    ];
    import('../common/util').then((_) => {
      _.loaderJs('http://res.wx.qq.com/open/js/jweixin-1.2.0.js').then(() => {
        window['wx'].config(wechatConfig);
        window['wx'].error(function (erroInfo) {
          window['wechatConfig'] = null;
        });
      }).catch(() => {
        window['wechatConfig'] = null;
      })
    })
  } else {
    window['wechatConfig'] = null;
  }
})(window)