'use strict';

import React, { Component } from 'react';
export default class CountDown extends Component<any, any> {
  timer;
  _isMounted;
  static defaultProps = {
    labelText: null,
    labelTextStyle: {},
    timeOffset: 0,
    overHandler: () => {},
    colorStyle: {},
    timeDaysStyle: {},
    showTimeDays: false, // 不展示剩N天的倒计时效果
    TimeDays: false, // 是否展示天数
    timeOnly: false, //只展示时间
    //倒计时结束的处理
    endHandle: () => {},
    //默认不展示
    visible: false
  };

  constructor(props) {
    super(props);
    this.state = {
      //默认倒计时时间，正整数，单位：秒
      timeOffset: this.props.timeOffset,
      visible: this.props.visible
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this._doCount();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.timeOffset != this.props.timeOffset) {
      if (this.props.timeOffset == 0) {
        this.setState({
          visible: false
        });
      }
      this.setState({
        timeOffset: nextProps.timeOffset,
        visible: nextProps.visible
      });
    }
  }

  render() {
    return this.state.visible && this.state.timeOffset >= 0 ? (
      <div style={styles.countDownBar}>
        <div>{this._timeFormat(this.state.timeOffset)}</div>
      </div>
    ) : null;
  }

  _timeFormat(timeOffset) {
    const day = Math.floor(timeOffset / (24 * 3600));
    const hour = Math.floor((timeOffset % (24 * 3600)) / 3600);
    const min = Math.floor((timeOffset % 3600) / 60);
    const second = timeOffset % 60;
    let trueDay = day < 10 ? '0' + day : day;
    let trueHour = hour < 10 ? '0' + hour : hour;
    let truemin = min < 10 ? '0' + min : min;
    let trueSec = second < 10 ? '0' + second : second;
    if (
      trueDay == '00' &&
      trueHour == '00' &&
      truemin == '00' &&
      trueSec == '00'
    ) {
      //计时结束回调
      this.props.endHandle();
    }

    return (
      <div
        style={
          this.props.colorStyle
            ? { ...styles.timeBg, ...this.props.colorStyle }
            : { ...styles.timeBg }
        }
      >
        {this.props.labelText ? (
          <span style={styles.countDown}>{this.props.labelText}&nbsp;</span>
        ) : null}

        {// 不展示剩N天的倒计时效果 默认为true
        // if true  show this 18:18:18:18
        // if false show this 剩20天 18:18:18
        this.props.showTimeDays
          ? trueDay != '00' && (
              <div>
                <span>{trueDay}:</span>
              </div>
            )
          : ''}
        {!this.props.showTimeDays
          ? trueDay != '00' && (
              <div>
                <span
                  style={{ ...styles.timeDays, ...this.props.timeDaysStyle }}
                >
                  {this.props.TimeDays && !this.props.timeOnly
                    ? ''
                    : !this.props.timeOnly
                      ? trueDay + '天'
                      : ''}
                </span>
              </div>
            )
          : ''}
        <div>
          <span>{trueHour}</span>
        </div>
        <span>:</span>
        <div>
          <span>{truemin}</span>
        </div>
        <span>:</span>
        <div>
          <span>{trueSec}</span>
        </div>
      </div>
    );
  }

  /**
   * 计时器倒计时
   */
  _doCount = () => {
    this.timer = setInterval(() => {
      if (this.state.timeOffset <= 1) {
        clearTimeout(this.timer);
        this.props.overHandler();
      }
      this.setState({
        timeOffset: this.state.timeOffset - 1
      });
    }, 1000);
  };
}

const styles = {
  countDownBar: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  icon: {
    width: 12,
    height: 12,
    marginRight: 10
  },
  timeDays: {
    marginRight: 3
  },
  timeBg: {
    borderRadius: 2,
    textAlign: 'center',
    justifyContent: 'center',
    color: '#fff',
    fontSize: 12,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  countDown: {
    fontSize: 13,
    color: '#000'
  }
} as any;
