import React, { ReactNode } from 'react';

const noop = () => {};

export interface IInputProps {
  label?: string;
  placeHolder?: string;
  //input onchange
  onChange?: Function;
  //其它节点
  other?: ReactNode;
  //是否必填
  required?: boolean;
  //默认值
  defaultValue?: string;
  maxLength?: number;
  pattern?: string;
  //是否可以编辑
  disabled?: boolean;
  //文本框的类型
  type?: string;
}

export default class FormInput extends React.Component<IInputProps, any> {
  static defaultProps = {
    onChange: noop
  };

  constructor(props: IInputProps) {
    super(props);
    this.state = {
      inputValue: '',
      errorPress: false
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ inputValue: nextProps.defaultValue });
  }

  render() {
    const {
      label,
      placeHolder,
      onChange,
      other,
      required,
      maxLength,
      pattern,
      disabled,
      type
    } = this.props;

    return (
      <div className="row form-item">
        {label && (
          <span className="form-text">
            {required && <i>*</i>}
            {label}
          </span>
        )}
        <input
          placeholder={placeHolder}
          type={type || type != '' ? type : 'text'}
          disabled={disabled}
          value={this.state.inputValue}
          pattern={pattern}
          className="form-input"
          step="1"
          maxLength={maxLength}
          onKeyDown={(e) => {
            this._handelOnKeyDown(e, type, pattern);
          }}
          onChange={(e) => this._handelOnChange(e, onChange, type, maxLength)}
          style={{ color: disabled ? '#999' : null, opacity: 1, backgroundColor:'white' }}
        />
        {other}
      </div>
    );
  }

  /**
   * 解决type='number'的时候，maxlength不兼容的问题
   *
   * @param e
   * @param onChangeFun
   * @param type
   * @param maxLength
   * @private
   */
  _handelOnChange = (e, onChangeFun, type, maxLength) => {
    if (this.state.errorPress) {
      e.target.value = ''; //110.和110。在react看来是一样的值，不会更新，所以先清空，再填值
      this.setState({ inputValue: this.state.inputValue });
      return;
    }
    var value = e.target.value;
    if (type == 'number' || type == 'tel') {
      if (value.length <= maxLength && e.target.validity.valid) {
        this.setState({ inputValue: value });
      } else {
        this.setState({
          inputValue: this.state.inputValue.slice(0, maxLength)
        });
        return;
      }
    } else {
      this.setState({ inputValue: value });
    }
    onChangeFun(e);
  };

  /**
   * 校验输入内容
   * @param e
   * @param type
   * @private
   */
  _handelOnKeyDown = (e, type, pattern) => {
    // if (e.keyCode == 8 || e.keyCode == 229) {//229是一个神秘的数字。。。。。安卓所有键盘输入，keyCode都是229
    //   this.setState({errorPress: false});
    // } else {
    //   if (pattern && e.nativeEvent.key) {
    //     if (!new RegExp("^" + pattern + "$").test(e.nativeEvent.key)) {
    //       this.setState({errorPress: true});
    //       return;
    //     }
    //   }
    //   this.setState({errorPress: false});
    // }
  };
}
