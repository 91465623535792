import React from "react";
import {fromJS, is} from 'immutable'

interface ButtonProps {
  clickStyle ?: object
  defaultStyle ?: object
  onClick: Function
  disabled ?: boolean
  text: string
  // 倒计时按钮Timer专用 倒计时时间
  countDownTime ?: number
  // 倒计时按钮Timer专用 倒计时中按钮展示文字
  countDownText ?: string
  // 倒计时前校验方法，返回false时不执行点击方法
  shouldStartCountDown ?: () => boolean
  // click方法如果是结果是Promise，catch异常情况是否重置Timer
  resetWhenError ?: boolean
}

/**
 * 基本按钮
 */
export default class Button extends React.Component<ButtonProps, any> {

  // 未点击状态的className
  defaultClassName: string
  // 点击后的className
  clickClassName: string
  // disabled的className
  disabledClassName: string
  // 按钮变色定时器
  timer: any

  state: {
    className: string
    style: object
    disabled: boolean
    processing: boolean
  }


  constructor(props) {
    super(props)
  }


  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.disabled !== this.state.disabled) {
      this.setState({disabled: nextProps.disabled})
    }
  }


  render() {
    return (
      <div className={this.getClassName()} style={this.getStyle()} onClick={() => this.handleClick()}>
        {this.props.text}
      </div>
    )
  }


  /**
   * 按钮class
   * @returns {string}
   */
  getClassName = () => {
    let className = this.state.disabled ? this.disabledClassName : this.state.className

    return className
  }

  /**
   * 获取按钮的style，待都改成className，这里可以改成由用户传入，自定义按钮的颜色
   * @returns {{}}
   */
  getStyle = () => {
    return this.state.style
  }


  /**
   * 处理按钮点击事件
   * 防止重复提交可以把 onClick 的返回值定义为 Promise
   */
  handleClick = () => {

    if (this.state.disabled || this.state.processing) {
      return
    }

    // 改变按钮颜色，设置按钮状态执行操作中
    this.setState({
      className: this.clickClassName,
      style: this.props.clickStyle,
      processing: true
    })

    // 定时后改回按钮默认颜色
    setTimeout(() => {
      this.setState({
        className: this.defaultClassName,
        style: this.props.defaultStyle
      })
    }, 100)

    // 执行 onClick 方法
    let result = this.props.onClick()

    // 如果是 Promise ，回调后再重置按钮为可点击
    if (result && result instanceof Promise) {
      result.then((res) => {
        this.setState({
          processing: false
        })
        return res
      }).catch((reason) => {
        this.setState({
          processing: false
        })
        return reason
      })
    } else { // onClick 返回的不是 Promise，直接改按钮为可用
      this.setState({
        processing: false
      })
    }

  }
}

/**
 * 占一行的长按钮，默认蓝色
 */
export class LongBlue extends Button {

  defaultClassName = 'btn btn-primary'
  clickClassName = 'btn btn-primary-click'
  disabledClassName = 'btn btn-disabled'

  constructor(props) {
    super(props)
    this.state = {
      className: this.defaultClassName,
      style: this.props.defaultStyle,
      disabled: this.props.disabled,
      processing: false
    }
  }

  render() {
    return (
      <div className={this.getClassName()} style={this.getStyle()} onClick={() => this.handleClick()}>
        {this.props.text}
      </div>
    )
  }

}


/**
 * 占一行的无色长按钮
 */
export class Long extends Button {

  defaultClassName = 'btn'
  clickClassName = 'btn btn-click'
  disabledClassName = 'btn btn-disabled'

  constructor(props) {
    super(props)
    this.state = {
      className: this.defaultClassName,
      style: this.props.defaultStyle,
      disabled: this.props.disabled,
      processing: false
    }
  }

  render() {
    return (
      <div className={this.getClassName()} style={this.getStyle()} onClick={() => this.handleClick()}>
        {this.props.text}
      </div>
    )
  }


}


/**
 * 提交按钮，红色
 */
export class Submit extends Button {

  defaultClassName = 'btn btn-submit'
  clickClassName = 'btn btn-submit-click'
  disabledClassName = 'btn btn-submit btn-disabled'
  pinkClassName = "btn btn-submit btn-pink"

  constructor(props) {
    super(props)
    this.state = {
      className: this.defaultClassName,
      style: this.props.defaultStyle,
      disabled: this.props.disabled,
      processing: false
    }
  }

  render() {
    return (
      <div className={this.getClassName()} style={this.getStyle()} onClick={() => this.handleClick()}>
        {this.props.text}
      </div>
    )
  }

}


/**
 * 普通按钮 中空 蓝色
 * //todo 现在套的 btn-small
 */
export class Blue extends Button {

  defaultClassName = 'btn btn-ghost btn-small'
  clickClassName = 'btn btn-ghost-click btn-small'
  disabledClassName = 'btn btn-disabled btn-small'

  constructor(props) {
    super(props)
    this.state = {
      className: this.defaultClassName,
      style: this.props.defaultStyle,
      disabled: this.props.disabled,
      processing: false
    }
  }

  render() {
    return (
      <div className={this.getClassName()} style={this.getStyle()} onClick={() => this.handleClick()}>
        {this.props.text}
      </div>
    )
  }


}


/**
 * 普通按钮 中空 红色
 * //todo 现在套的 btn-small
 */
export class Red extends Button {

  defaultClassName = 'btn btn-ghost-red btn-small'
  clickClassName = 'btn btn-ghost-red-click btn-small'
  disabledClassName = 'btn btn-disabled btn-small'

  constructor(props) {
    super(props)
    this.state = {
      className: this.defaultClassName,
      style: this.props.defaultStyle,
      disabled: this.props.disabled,
      processing: false
    }
  }

  render() {
    return (
      <div className={this.getClassName()} style={this.getStyle()} onClick={() => this.handleClick()}>
        {this.props.text}
      </div>
    )
  }


}

/**
 * 小号按钮 中空 蓝色
 */
export class SmallBlue extends Button {

  defaultClassName = 'btn btn-ghost btn-small'
  clickClassName = 'btn btn-ghost-click btn-small'
  disabledClassName = 'btn btn-disabled btn-small'

  constructor(props) {
    super(props)
    this.state = {
      className: this.defaultClassName,
      style: this.props.defaultStyle,
      disabled: this.props.disabled,
      processing: false
    }
  }

  render() {
    return (
      <div className={this.getClassName()} style={this.getStyle()} onClick={() => this.handleClick()}>
        {this.props.text}
      </div>
    )
  }


}

/**
 * 小号按钮 中空 红色
 */
export class SmallRed extends Button {

  defaultClassName = 'btn btn-ghost-red btn-small'
  clickClassName = 'btn btn-ghost-red-click btn-small'
  disabledClassName = 'btn btn-disabled btn-small'

  constructor(props) {
    super(props)
    this.state = {
      className: this.defaultClassName,
      style: this.props.defaultStyle,
      disabled: this.props.disabled,
      processing: false
    }
  }

  render() {
    return (
      <div className={this.getClassName()} style={this.getStyle()} onClick={() => this.handleClick()}>
        {this.props.text}
      </div>
    )
  }


}


/**
 * 倒计时按钮 蓝色 中空
 */
export class Timer extends Button {

  defaultClassName = 'send-code'
  clickClassName = 'send-code'
  disabledClassName = 'send-code btn-disabled'
  // 倒计时定时器
  timer: any

  state: {
    className: string
    style: object
    disabled: boolean
    processing: boolean

    text: string
    time: number
  }

  static defaultProps = {
    countDownTime: 60,
    countDownText: '重新发送', // 发送按钮文本
  }

  constructor(props: Object) {
    super(props);
    this.state = {
      className: this.defaultClassName,
      style: this.props.defaultStyle,
      disabled: this.props.disabled,
      processing: false,

      text: this.props.text,  // 发送按钮文本
      time: this.props.countDownTime, // 两次获取验证码间隔时间
    };
  }

  componentWillUnmount() {
    clearInterval(this.timer); // 清除定时器, 防止内存泄露;
  }


  /**
   * 按钮所在页面发生改变，重新渲染时，要保证按钮的倒计时状态及样式不改变
   * @param nextProps
   * @param nextState
   */
  componentWillReceiveProps(nextProps) {

    // 主要属性是否改变
    let sameProps = is(fromJS({
      style: nextProps.defaultStyle,
      disabled: nextProps.disabled,
      text: nextProps.text,
      time: nextProps.countDownTime,
    }), fromJS({
      style: this.props.defaultStyle,
      disabled: this.props.disabled,
      text: this.props.text,
      time: this.props.countDownTime,
    }));

    // 属性改变了，需要更新。否则，不处理
    if (!sameProps) {
      this.setState({
        style: nextProps.defaultStyle,
        disabled: nextProps.disabled,
        text: nextProps.text,
        time: nextProps.countDownTime,
      })
    }

  }


  render() {
    return (
      <div className={this.getClassName()} style={this.getStyle()} onClick={() => this._handleClick()}>
        {this.state.text}
      </div>
    )
  }


  _handleClick() {
    if (!this.state.disabled) {

      // 传入了校验方法，并且校验不通过
      if (this.props.shouldStartCountDown && this.props.shouldStartCountDown() === false) {
        return
      }

      this.setState({
        className: this.clickClassName,
        style: this.props.clickStyle,
        disabled: true
      })
      const clickResult = this.props.onClick();

      if (clickResult instanceof Promise) {
        clickResult.catch(error => {

          // 异常情况是否重置按钮
          if (this.props.resetWhenError) {
            this._enableBtn()
          }

        })
      }

      this._timer()
    }
  }


  /**
   * 倒计时
   */
  _timer = () => {
    this.timer = setInterval(() => {
      if (this.state.time == 0) {

        this._enableBtn()
        return
      }

      this._disableBtn()

    }, 1000)
  }


  _enableBtn() {
    this.setState({
      disabled: false,
      text: this.props.countDownText,
      time: this.props.countDownTime,
    });

    clearInterval(this.timer);
  }


  _disableBtn() {
    const sendButtonText = this.props.countDownText + '(' + this.state.time + ')';
    this.setState({
      disabled: true,
      text: sendButtonText,
      time: --this.state.time
    })
  }
}
