import React, { Component } from 'react';
import { Picker } from 'antd-mobile';
import businessIndustries from '../json/business/businessIndustry.json';

export default class BusinessIndustryPicker extends Component<any, any> {
  render() {
    return (
      <Picker
        data={businessIndustries}
        cols={1}
        {...this.props as any}
      >
        <Wrapper content={this.props.children as any}>
        </Wrapper>
      </ Picker>
    );
  }
}


class Wrapper extends React.Component<any, any> {

  render() {
    // 需要调用onClick方法弹出地址组件
    const { onClick, content } = this.props as any;
    return (
      <div onClick={onClick || undefined}>
        {content}
      </div>
    );
  }
}
