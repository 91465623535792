import React, { Component } from 'react';
import { Map, List, fromJS, is } from 'immutable';
import { WMImage, NumberInput, Button, _, WMkit } from 'wmkit';
import { cache } from 'config';
import { MarketingLabel } from 'biz';
import {
  createImmutableData,
  calculateSpeInfo,
  changeSpecDetail,
  returnStockFlag,
  changeNum,
  immediateBuy
} from './state-change';

const SubmitButton = Button.Submit;
const styles = require('../css/style.css');

/**
 * 拼团-规格选择弹框
 */
export default class WMGrouponChoose extends Component<any, any> {
  props: {
    grouponData: any;
    data: any;
    visible: boolean;
    changeSpecVisible: Function;
    dataCallBack?: Function;
    openGroupon: boolean;
    grouponNo?: string;
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillReceiveProps(nextProps) {
    const goods = nextProps.data || {};
    const thisGoods = this.props.data || {};
    if (
      !is(fromJS(goods), fromJS(thisGoods)) &&
      (nextProps.data && nextProps.data.skuId)
    ) {
      // 组装层级结构的规格数据
      const dataIm = createImmutableData(nextProps.data);
      // 主要是计算每个规格值是否灰化不可点击, 以及计算得出当前的sku
      this.setState({
        ...dataIm,
        ...calculateSpeInfo(dataIm)
      });
    }
  }

  render() {
    const { visible, changeSpecVisible, grouponData, openGroupon } = this.props;
    const {
      goods = Map(),
      goodsInfo = Map(),
      goodsInfoCache = Map(), //缓存之前选择的sku,防止用户取消选中规格时,无信息展示的问题
      goodsSpecs = List()
    } = this.state;

    const grouponActivity = grouponData
      ? fromJS(grouponData).get('grouponActivity')
      : Map();

    const noSpecStockFlag = returnStockFlag(
      goodsInfo.get('stock'),
      goodsInfo.get('count')
    );

    // 划线价
    const lineShowPrice = this._originPriceInfo(
      goods.get('linePrice'),
      goodsInfo,
      goodsInfoCache
    );

    // 社交电商相关内容显示与否
    const social = goodsInfo.get('distributionGoodsAudit') == 2 ? true : false;
    const isDistributor = WMkit.isShowDistributionButton();
    //禁用分享赚
    const socialDisabled = false;

    return (
      <div style={{ display: !visible || !goods ? 'none' : 'block' }}>
        <div
          className="choose-mask"
          onClick={() => {
            changeSpecVisible(false);
          }}
        />
        <div className="choose-body">
          <div className="choose-content">
            {/*弹窗关闭按钮*/}
            <div
              className="close"
              onClick={() => {
                changeSpecVisible(false);
              }}
            >
              <i className="iconfont icon-guanbi" />
            </div>

            {/*sku图文信息*/}
            <div className="choose-top b-1px-b">
              <WMImage
                src={goodsInfoCache.get('goodsInfoImg')}
                alt=""
                width="100"
                height="100"
              />
              <div className="title-box">
                <p className="title">{goods ? goods.get('goodsName') : ' '}</p>
                <p className="price">
                  ¥
                  {social
                    ? _.addZero(goodsInfo.get('grouponPrice'))
                    : _.addZero(
                        goodsInfo.get('grouponPrice') ||
                          goodsInfoCache.get('grouponPrice')
                      )}
                  &nbsp;
                  {!!lineShowPrice && (
                    <span className="delPrice">
                      ¥{_.addZero(lineShowPrice)}
                    </span>
                  )}
                  {social &&
                    isDistributor &&
                    !sessionStorage.getItem(cache.INVITEE_ID) && (
                      <span className="commission">
                        /&nbsp;赚
                        {_.addZero(goodsInfo.get('distributionCommission'))}
                      </span>
                    )}
                </p>
                {!social &&
                  !isDistributor && (
                    <div className="level-box">
                      <MarketingLabel
                        marketingLabels={
                          goodsInfo.get('marketingLabels') ||
                          goodsInfoCache.get('marketingLabels')
                        }
                        couponLabels={
                          goodsInfo.get('couponLabels') ||
                          goodsInfoCache.get('couponLabels')
                        }
                      />
                    </div>
                  )}
              </div>
            </div>

            {/*sku中间滚动区域*/}
            <div className="choose-center">
              {/*sku选择规格*/}
              {goodsSpecs &&
                goodsSpecs.size > 0 &&
                goodsSpecs.toJS().map((spec, index) => {
                  return (
                    <div className="spec-box" key={spec.specId}>
                      <label className="spec-title">{spec.specName}</label>
                      <div className="spec-item">
                        {spec.specDetails.map((det) => {
                          return (
                            <span
                              key={det.specDetailId}
                              onClick={
                                spec.defaultVal != det.specDetailId &&
                                det.disabled
                                  ? () => {}
                                  : () => {
                                      this._changeSpecDetail(
                                        spec.defaultVal == det.specDetailId
                                          ? null
                                          : det.specDetailId,
                                        index
                                      );
                                    }
                              }
                              className={
                                spec.defaultVal == det.specDetailId
                                  ? 'spec-items checked'
                                  : det.disabled
                                    ? 'spec-items invalid'
                                    : 'spec-items'
                              }
                            >
                              {det.detailName}
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}

              {/*sku选择数量*/}
              <div className="sku-num retail-num">
                <div className="sku-bottom">
                  <label className="spec-title">数量</label>
                  <div className="row-flex">
                    <span className="other-text">
                      {!social && (
                        <span>
                          {goodsInfo.get('count') &&
                            goodsInfo.get('count') + '起订'}
                          {null != goodsInfo.get('maxCount') &&
                            '，' + '限订' + goodsInfo.get('maxCount')}
                          &nbsp;&nbsp;
                        </span>
                      )}
                      库存{goodsInfo.get('stock') || 0}
                      {goods.get('goodsUnit')}&nbsp;&nbsp;
                    </span>
                    <NumberInput
                      disableNumberInput={noSpecStockFlag}
                      value={noSpecStockFlag ? 0 : goodsInfo.get('num')}
                      max={this._maxNum(goodsInfo)}
                      onDelayChange={(value) =>
                        this._changeNum(
                          value,
                          goodsInfo.get('stock'),
                          goodsInfo.get('goodsInfoId')
                        )
                      }
                      min={goodsInfo.get('count')}
                      error={goodsInfo.get('error')}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="bottom b-1px-t">
              <span className="normal-text">
                已选{goodsInfo.get('num') || 0}
                {goods.get('goodsUnit')}
              </span>

              {/* <SubmitButton
                text={this._renderSimpleHtml(lineShowPrice)}
                onClick={() => this._go()}
              /> */}
              <SubmitButton
                defaultStyle={{
                  lineHeight: 'normal',
                  backgroundColor: '#ff1f4e',
                  borderColor: '#ff1f4e',

                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '0.9rem'
                }}
                disabled={!goodsInfo.get('num')}
                text={this._renderGrouponHtml(goodsInfo, grouponActivity)}
                onClick={() => this._immediateBuy(openGroupon)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
  _go = () => {};
  _renderSimpleHtml = (lineShowPrice) => {
    let html = null;
    html = (
      <div>
        <div>￥{_.addZero(lineShowPrice)}</div>
        <div>单独购买</div>
      </div>
    );

    return html;
  };

  _renderGrouponHtml = (goodsInfo, grouponActivity) => {
    let html = null;
    html = (
      <div>
        <div>￥{_.addZero(goodsInfo.get('grouponPrice'))}</div>
        <div>{grouponActivity ? grouponActivity.get('grouponNum') : 0}人拼</div>
      </div>
    );
    return html;
  };

  /**
   * 获取是否展示划线价,以及划线价
   *   a.若划线价存在,则展示
   *   b.若划线价不存在
   *     b.1.登录前,不展示
   *     b.2.登陆后,展示sku市场价
   * @private
   */
  _originPriceInfo = (linePrice, goodsInfoIm, goodsInfoCache) => {
    if (linePrice) {
      return linePrice;
    } else {
      if (WMkit.isLoginOrNotOpen()) {
        return (
          goodsInfoIm.get('marketPrice') || goodsInfoCache.get('marketPrice')
        );
      } else {
        return (
          goodsInfoIm.get('marketPrice') || goodsInfoCache.get('marketPrice')
        );
      }
    }
  };

  /**
   * 切换选中前n-1个规格项的规格值
   * @param specDetailId
   * @param index
   * @private
   */
  _changeSpecDetail = (specDetailId, index) => {
    this.setState(changeSpecDetail(this.state, specDetailId, index), () => {
      if (this.props.dataCallBack) {
        this.props.dataCallBack(this.state.goodsInfo);
      }
    });
  };
  // 限定量
  _maxNum = (goodsInfo) => {
    if (
      goodsInfo.get('maxCount') &&
      goodsInfo.get('maxCount') < goodsInfo.get('stock')
    ) {
      return goodsInfo.get('maxCount');
    } else {
      return goodsInfo.get('stock');
    }
  };

  /**
   * 用户改变sku购买数量
   * @param num 数量
   * @param stock 库存
   * @param goodsInfoId sku标识
   * @private
   */
  _changeNum = (savedNum, stock, goodsInfoId) => {
    savedNum = stock > 0 ? (savedNum < stock ? savedNum : stock) : 0;
    this.setState(changeNum(this.state, { num: savedNum, goodsInfoId }));
  };

  /**
   * 立即购买
   */
  _immediateBuy = async (openGroupon) => {
    //  开团
    if (openGroupon) {
      await immediateBuy(this.state, openGroupon, this.props.grouponNo);
    } else {
      await immediateBuy(this.state, openGroupon, this.props.grouponNo);
    }
  };
}
