import React from 'react'
import {noop} from 'wmkit'

/**
 * 公共RadioBox单选按钮(方)
 */
export default class RadioBox extends React.PureComponent<any, any> {

  static defaultProps = {
    //选中值
    checked: 0,
    //选择方法
    onCheck: noop,
    //样式
    style: {},
    //选项数据
    data: [],
  }

  render() {
    const { checked , style, data} = this.props
    return (
      <div className="select-reason">
        {
          data.map(val => {
           return (
             <div className={`select-btn ${ checked == val.id ? 'select-btn-checked' : null}`}
                  style={{...style}}
                  onClick={() => this._onCheck(val.id)}>
               {val.name}
               {
                 checked == val.id ? <i className="iconfont icon-gou"></i> : null
               }
             </div>
           )
          })
        }
      </div>
    )
  }

  _onCheck = (k) => {
    this.props.onCheck(k)
  }
}
