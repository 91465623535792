import { cache, config } from 'config';
import moment from 'moment';
import { msg } from 'plume2';
import { Alert, history, storage, WMkit, _ } from 'wmkit';
import { mergePurchase } from 'biz';
import wx from 'weixin-js-sdk';

/**
 * 格式化时间
 *
 */
Date.prototype.Format = function(fmt) {
  var o = {
    'M+': this.getMonth() + 1, //月份
    'd+': this.getDate(), //日
    'h+': this.getHours(), //小时
    'm+': this.getMinutes(), //分
    's+': this.getSeconds(), //秒
    'q+': Math.floor((this.getMonth() + 3) / 3), //季度
    S: this.getMilliseconds() //毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (this.getFullYear() + '').substr(4 - RegExp.$1.length)
    );
  for (var k in o)
    if (new RegExp('(' + k + ')').test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
      );
  return fmt;
};

/**
 * 把长整类型的时间转换成 yyyy-MM-dd 格式的时间
 */
export const formatDay = function(value) {
  var defaultFmt = 'YYYY-MM-DD';
  // var date = new Date(value);
  // return date.Format(defaultFmt);
  return moment(value).format(defaultFmt);
};

/**
 * 把长整类型的时间转换成yyyy-MM-dd hh:mm:ss格式的时间
 */
export const formatDate = function(value) {
  var defaultFmt = 'YYYY-MM-DD HH:mm:ss';
  // var date = new Date(value);
  // return date.Format(defaultFmt);
  return moment(value).format(defaultFmt);
};

/**
 * 把长整类型的时间转换成yyyy年MM月dd日 hh时mm分ss秒格式的时间
 */
export const formatChnDate = function(value) {
  var defaultFmt = 'YYYY年MM月DD日 HH时mm分ss秒';
  // var date = new Date(value);
  // return date.Format(defaultFmt);
  return moment(value).format(defaultFmt);
};

/**
 * 判断是否在微信中打开
 */
export const isWeixin = function() {
  let ua = window.navigator.userAgent.toLowerCase();
  if (ua) {
    return ua.indexOf('micromessenger') > -1;
  } else {
    return false;
  }
};

/**
 * 获取小数点后数字长度
 * @author zhongjiewang
 * @param  {Number} num 数字
 * @return {Number}     长度
 */
function decimalLength(num) {
  var str = num.toString();
  var index = str.indexOf('.');
  return index == -1 ? 0 : str.substr(index + 1).length;
}

/**
 * 小数点后补齐0作为整数
 * @author zhongjiewang
 * @param  {Number} num    数字
 * @param  {Number} length 补齐的长度
 * @return {Number}        整数
 */
function suffixInteger(num, length) {
  var str = num.toString();
  var decimalLen = decimalLength(num);
  str += Math.pow(10, length - decimalLen)
    .toString()
    .substr(1);
  return Number(str.replace('.', ''));
}

/**
 * 浮点数相乘
 * 使用：num1.mul(num2);
 * return 相乘结果
 */
export const mul = function(num1, num2) {
  var r1 = decimalLength(num1);
  var r2 = decimalLength(num2);

  var max = Math.max(r1, r2);

  var n1 = suffixInteger(num1, max);
  var n2 = suffixInteger(num2, max);

  return (n1 * n2) / Math.pow(10, max * 2);
};

/**
 * 浮点数相加
 */
export const add = function(num1, num2) {
  var r1 = decimalLength(num1);
  var r2 = decimalLength(num2);

  var max = Math.max(r1, r2);

  var n1 = suffixInteger(num1, max);
  var n2 = suffixInteger(num2, max);

  return Number(((n1 + n2) / Math.pow(10, max)).toFixed(max));
};

/**
 ** 减法函数，用来得到精确的减法结果
 ** 说明：javascript的减法结果会有误差，在两个浮点数相减的时候会比较明显。这个函数返回较为精确的减法结果。
 ** 返回值：arg1加上arg2的精确结果
 **/
export const sub = function(num1, num2) {
  var r1 = decimalLength(num1);
  var r2 = decimalLength(num2);

  var max = Math.max(r1, r2);

  var n1 = suffixInteger(num1, max);
  var n2 = suffixInteger(num2, max);

  return Number(((n1 - n2) / Math.pow(10, max)).toFixed(max));
};

/**
 * 除法函数
 * @param num1
 * @param num2
 * @returns {number}
 */
export function div(num1, num2) {
  var r1 = decimalLength(num1);
  var r2 = decimalLength(num2);

  var max = Math.max(r1, r2);

  var n1 = suffixInteger(num1, max);
  var n2 = suffixInteger(num2, max);

  return n1 / n2;
}

/**
 * 判断是否是空对象
 * @param obj
 * @returns {boolean}
 */
export const isEmptyObject = function(obj) {
  for (var i in obj) {
    if (obj.hasOwnProperty(i)) {
      return false;
    }
  }
  return true;
};

/**
 * 为整数添加两位小数
 * 向下截取小数点后两位,如:1.036 -> 1.03
 */
export const addZeroFloor = (num) => {
  var f = parseFloat(num ? num : 0);
  f = Math.floor(f * 100) / 100;
  return f.toFixed(2);
};

/**
 * 为整数添加两位小数
 * 四舍五入
 */
export const addZero = function(num) {
  return new Number(num ? num : 0).toFixed(2);
};

/**
 * 规则：6-20，数字、字母和符号
 * 数字：6-10弱，11及后为中    可通过验证，but提示：您的密码过于简单，有被盗风险，建议您修改
 * 字母：6-10弱，11及后为中    可通过验证，but提示：您的密码过于简单，有被盗风险，建议您修改
 * 字符：6-10弱，11及后为中    可通过验证，but提示：您的密码过于简单，有被盗风险，建议您修改
 * 数字、字母或字符两者组合：6- 10中 ，11及后为强
 * 数字、字母和字符三者组合：6及后为强
 * @param pwdValue
 * @returns {*} [1,2,3]
 */
export const getLevel = (pwdValue): Number => {
  var pattern_1 = /^.*([\W_])+.*$/i;
  var pattern_2 = /^.*([a-zA-Z])+.*$/i;
  var pattern_3 = /^.*([0-9])+.*$/i;
  var level = 0;
  if (pwdValue.length > 10) {
    level++;
  }
  if (pattern_1.test(pwdValue)) {
    level++;
  }
  if (pattern_2.test(pwdValue)) {
    level++;
  }
  if (pattern_3.test(pwdValue)) {
    level++;
  }
  if (level > 3) {
    level = 3;
  }
  return level;
};

export const clearImgDomain = (src: string) => {
  return src.replace(config.IMAGE_SERVER, '');
};

export const getSizedImagePath = (src: string) => {
  if (!src) return '';
  return `${config.IMAGE_SERVER}${src}`;
};

/**
 * 格式化金额
 * @param s 金额数
 * @param n 显示小位数
 * @param fp 标准金额展示
 * @returns {string}
 */
export const fmoney = (s, n = 2, fp = undefined) => {
  n = n > 0 && n <= 20 ? n : 2;
  s = parseFloat((s + '').replace(/[^\d\.-]/g, '')).toFixed(n) + '';
  var l = s
      .split('.')[0]
      .split('')
      .reverse(),
    r = s.split('.')[1];
  var t = '';
  for (var i = 0; i < l.length; i++) {
    if (fp) {
      t += l[i] + ((i + 1) % 3 == 0 && i + 1 != l.length ? ',' : '');
    } else {
      t += l[i];
    }
  }

  return (
    t
      .split('')
      .reverse()
      .join('') +
    '.' +
    r
  );
};
/**
 * 不四舍五入的取两位小数
 */
export const toFixed2 = (number: number | string) => {
  if (typeof number != 'string') {
    number = number.toString();
  }
  let numberArray = number.split('.');
  if (numberArray[1]) {
    if (numberArray[1].length == 1) {
      numberArray[1] = numberArray[1] + '0';
    } else if (numberArray[1].length > 2) {
      numberArray[1] = numberArray[1].substring(0, 2);
    }
  } else {
    numberArray[1] = '00';
  }
  return parseFloat(numberArray.join('.')).toFixed(2);
};

/**
 * 异步加载js
 */
export const loaderJs = (jsSrc) => {
  return new Promise((resolve, reject) => {
    let head = document.getElementsByTagName('head')[0];
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.charset = 'utf-8';
    script.async = true;
    script.src = jsSrc;
    head.appendChild(script);

    script.onload = function() {
      resolve();
    };

    script.onerror = function(err) {
      reject(err);
    };
  });
};

export const safeMobile = (mobile) => {
  if (!mobile) {
    return mobile;
  }

  let mobileStr = mobile.toString();
  if (mobileStr.length > 7) {
    return `${mobileStr.slice(0, 3)}****${mobileStr.slice(7)}`;
  }
  return mobile.toString();
};

/**
 * 唤起qq客服
 * @param qq qq号
 */
export function qqCustomerService(qq) {
  const bIsWeixin =
    navigator.userAgent.toLowerCase().indexOf('micromessenger') > -1;
  if (bIsWeixin) {
    wx.miniProgram.getEnv(function(res) {
      if (res.miniprogram) {
        wx.miniProgram.navigateTo({
          url: `../qqservice/qqservice?data=${JSON.stringify(qq)}`
        });
      } else {
        window.location.href = `https://wpa.qq.com/msgrd?v=3&uin=${qq}&site=qq&menu=yes`;
      }
    });
  } else {
    window.location.href = `mqqwpa://im/chat?chat_type=wpa&uin=${qq}&version=1&src_type=web`;
  }
}

/**
 * oss相关，为分享功能压缩图片，微信分享要求图片小于32k
 * @param {*} imgUrl
 */
export const suffixForShare = (imgUrl) => {
  //目前写死，后期会请求后台，查询当前使用的oss，修改后缀名
  return `${imgUrl}?x-oss-process=image/resize,w_100,h_100/quality,q_80`;
};

/**
 * 产生指定位数的随机数
 * @param n
 * @returns {string}
 */
export const rndNum = (n) => {
  let rnd = '';
  for (let i = 0; i < n; i++) rnd += Math.floor(Math.random() * 10);
  return rnd;
};

/**
 * 根据登录后的返回参数跳转到相应页面
 * @param context
 */
export const switchLogin = (context) => {
  const couponResponse = context.couponResponse;
  // 首先区分企业购会员
  if(context.enterpriseCheckState === 2){
    // 审核通过
    setLoginSuccessInfo(context);
    showRegisterModel(couponResponse, true);
    return;
  } else if(context.enterpriseCheckState === 1 || context.enterpriseCheckState === 3){
    // 待审核或审核不通过
    WMkit.clearLoginCache();
    //将审核中的账户信息存入本地缓存
    storage('local').set(cache.PENDING_AND_REFUSED, JSON.stringify(context));
    history.push({
      pathname: `/improve-iep-info/${context.customerId}`,
      // state: { context: context }
    });
    showRegisterModel(couponResponse, false);
    return;
  }
  // 普通会员
  switch (context.checkState) {
    /**审核中*/
    case 0:
      WMkit.clearLoginCache();
      //将审核中的账户信息存入本地缓存
      storage('local').set(cache.PENDING_AND_REFUSED, JSON.stringify(context));
      history.push(`/improve-information/${context.customerId}`);
      showRegisterModel(couponResponse, false);
      break;
    /**审核通过，成功登录*/
    case 1:
      setLoginSuccessInfo(context);
      showRegisterModel(couponResponse, true);
      break;
    /**审核未通过*/
    default:
      WMkit.clearLoginCache();
      //将审核中的账户信息存入本地缓存
      storage('local').set(cache.PENDING_AND_REFUSED, JSON.stringify(context));
      history.push(`/improve-information/${(context as any).customerId}`);
  }
};

/**
 * 设置登录成功后的信息
 */
export const setLoginSuccessInfo = (context) => {
  // a.设置登陆后token以及登陆信息缓存
  window.token = context.token;
  localStorage.setItem(cache.LOGIN_DATA, JSON.stringify(context));
  sessionStorage.setItem(cache.LOGIN_DATA, JSON.stringify(context));
  WMkit.setIsDistributor();
  // 登录成功，发送消息，查询分销员信息 footer/index.tsx
  msg.emit('userLoginRefresh');
  // b.登陆成功,需要合并登录前和登陆后的购物车
  mergePurchase(null);
  // c.登陆成功,跳转拦截前的路由
  Alert({ text: '登录成功' });
  const local = sessionStorage.getItem(cache.TARGET_PAGES)
    ? JSON.parse(sessionStorage.getItem(cache.TARGET_PAGES))
    : {};
  sessionStorage.removeItem(cache.TARGET_PAGES);
  //小程序环境下，需要结合具体的分享赚链接，跳转到适当的页面
  if ((window as any).isMiniProgram) {
    if (WMkit.inviteeId()) {
      //分销渠道为店铺，去店铺首页
      if (WMkit.channelType() == '2') {
        history.push(`/shop-index-c/${WMkit.inviteeId()}`);
      } else {
        //去商城首页或者携带XXX的店铺的商城首页
        history.push(`/?inviteeId=${WMkit.inviteeId()}`);
      }
    } else {
      history.push('/');
    }
  } else {
    history.push({
      pathname: local.pathname || '/',
      ...local
    });
    history.push('/');
  }
}

/**
 * 展示注册赠券信息
 * @param couponResponse
 * @param isShowButton
 */
export const showRegisterModel = (couponResponse, isShowButton?) => {
  if (couponResponse && couponResponse.couponList.length > 0) {
    let coupon = {
      // 是否显示弹框
      visible: WMkit.isShop() == undefined ? true : !WMkit.isShop(),
      // 是否展示我的优惠券按钮
      isShowButton: isShowButton,
      //优惠券列表
      couponList: couponResponse.couponList,
      //活动标题
      title: couponResponse.title,
      //活动描述
      desc: couponResponse.desc,
      // true  为注册赠券  false 进店赠券
      isStoreModel: true
    };
    if (isShowButton != undefined) {
      // 是否展示我的优惠券按钮
      coupon['isShowButton'] = isShowButton;
      coupon['isStoreModel'] = false;
    }
    msg.emit('registerCouponVisible', coupon);
  }
};

const PUSHTYPE = {
  GOODS: 0,
  GOODSINFO: 1,
  GOODSGROUP: 2,
  CATE: 3,
  STORE: 4,
  MARKETING: 5,
  PAGE: 6,
  ACCOUNT: 7,
  PROPERTY: 8,
  ORDER: 9,
  RETUN_ORDER: 10,
  DISTRIBUTE: 11,
  USERPAGE: 12
};

export const pageReplace = function(data) {
  switch (data.type) {
    case PUSHTYPE.GOODS:
      history.push('/goods-detail/'+data.skuId);
      break;
    case PUSHTYPE.GOODSINFO:
      break;
    case PUSHTYPE.GOODSGROUP:
      break;
    case PUSHTYPE.CATE:
      history.push(`/goodsList?cid=${data.cateId}&cname=${data.cateName}`);
      break;
    case PUSHTYPE.STORE:
      history.push('/store-main/'+data.storeId)
      break;
    case PUSHTYPE.MARKETING:
      if(data.node == 0){
        history.push(`/spellgroup-detail/${data.skuId}`)
      }else if(data.node == 1){
        history.push(`/goods-detail${data.skuId}`);
      }else if(data.node == 2){
        history.push('/goods-list-promotion/'+data.mid);
      }
      break;
    case PUSHTYPE.PAGE:
      history.push(`/page/${data.pageType}/${data.pageCode}`);
      break;
    case PUSHTYPE.USERPAGE:
      history.push(data.wechatPath);
      break;
    // case PUSHTYPE.ACCOUNT:
    //   msg.emit('router: goToNext', {
    //     routeName: data.page
    //   });
    //   break;
    case PUSHTYPE.PROPERTY:
      if (data.node == 0 || data.node == 1) {
        history.push('/my-coupon');
      } else if (data.node == 2 || data.node == 3 || data.node == 4) {
        history.push('/user-integral');
      } else if (data.node == 5) {
        history.push('/growth-value');
      } else if (data.node == 6) {
        history.push('/balance/account-detail');
      } else if (data.node == 7 || data.node == 8 || data.node == 9) {
        history.push('/balance/cash-detail/'+data.id);
      }
      break;
    case PUSHTYPE.ORDER:
      if (
        data.node == 0 ||
        data.node == 1 ||
        data.node == 2 ||
        data.node == 3 ||
        data.node == 4 ||
        data.node == 5
      ) {
        history.push('/order-detail/'+data.id);
      } else if (data.node == 6) {
        history.push('/evaluate/evaluate-center');
      } else if (data.node == 7) {
        history.push({
          pathname: '/evaluate/evaluate-drying',
          state: {
            storeId: data.storeId,
            orderId: data.tid,
            goodsInfoId: data.skuId,
            evaluateType: 'goodsEvaluate'
          }});
      } else if (data.node == 8) {
        history.push({
          pathname: '/evaluate/evaluate-drying',
          state: {
            storeId: data.storeId,
            orderId: data.tid,
            goodsInfoId: data.skuId,
            evaluateType: 'storeEvaluate'
          }});
      } else if (
        data.node == 9 ||
        data.node == 10 ||
        data.node == 11 ||
        data.node == 12
      ) {
        history.push('/group-buy-detail/'+data.id)
      }
      break;
    case PUSHTYPE.RETUN_ORDER:
      if (
        data.node == 0 ||
        data.node == 1 ||
        data.node == 2 ||
        data.node == 3 ||
        data.node == 4 ||
        data.node == 5
      ) {
        history.push('/return-detail/'+data.id);
      }
      break;
    case PUSHTYPE.DISTRIBUTE:
      if (data.node == 0) {
        history.push('/customer-order-detail/'+data.id);
      } else {
        history.push('/social-c/my-customer');
      }
      break;
  }
}
