import React, { Component } from 'react';
import { DatePicker } from 'antd-mobile';

export default class P extends Component<any, any> {

  render() {
    return (
      <DatePicker
        mode="date"
        minDate={new Date('1900-01-01')}
        maxDate={new Date()}
        {...this.props}
      >
        <Wrapper content={this.props.children as any}/>
      </DatePicker>
    );
  }
}


class Wrapper extends React.Component<any, any> {

  render() {
    // 需要调用onClick方法弹出地址组件
    const { onClick, content } = this.props as any;
    return (
      <div onClick={onClick || undefined}>
        {content}
      </div>
    );
  }
}
