/**
 * Created by chenpeng on 2017/7/25.
 */
import { IMap } from 'plume2';
import { FindArea, _ } from 'wmkit';
import { fromJS, Map } from 'immutable';

const FLOW_STATE = {
  INIT: '待审核',
  GROUPON: '待成团',
  AUDIT: '待发货',
  DELIVERED_PART: '待发货',
  DELIVERED: '待收货',
  CONFIRMED: '已收货',
  COMPLETED: '已完成',
  VOID: '已作废'
};

const PAY_STATUS = {
  NOT_PAID: '未付款',
  UNCONFIRMED: '待确认',
  PAID: '已付款'
};

const DELIVERY_STATUS = {
  NOT_YET_SHIPPED: '未发货',
  PART_SHIPPED: '部分发货',
  SHIPPED: '全部发货'
};

/**
 * 订单状态
 * @type {{INIT: string; GROUPON: string; AUDIT: string; DELIVERED_PART: string; DELIVERED: string; CONFIRMED: string; COMPLETED: string; VOID: string}}
 */
const flowState = (status, payState) => {
  if (status == 'INIT') {
    return '待审核';
  } else if (status == 'GROUPON') {
    // 是拼团订单 根据支付状态 ? 待支付 : 待发货
    if (payState == 'NOT_PAID') {
      return '待支付';
    } else if (payState == 'UNCONFIRMED') {
      return '待确认';
    } else if (payState == 'PAID') {
      return '待发货';
    }
  } else if (status == 'AUDIT' || status == 'DELIVERED_PART') {
    return '待发货';
  } else if (status == 'DELIVERED') {
    return '待收货';
  } else if (status == 'CONFIRMED') {
    return '已收货';
  } else if (status == 'COMPLETED') {
    return '已完成';
  } else if (status == 'VOID') {
    return '已作废';
  }
};

const invoiceType = (type: string) => {
  if (type == '0') {
    return '普通发票';
  } else if (type == '1') {
    return '增值税专用发票';
  } else if (type == '-1') {
    return '不需要发票';
  }
};

class WrapperOrder {
  order: IMap;

  constructor(order: IMap) {
    this.order = order;
  }

  orderNo() {
    return this.order.get('id');
  }

  /**
   * 订单状态
   * @returns {any}
   */
  orderState() {
    // return FLOW_STATE[this.order.getIn(['tradeState', 'flowState'])];
    return flowState(
      this.order.getIn(['tradeState', 'flowState']),
      this.order.getIn(['tradeState', 'payState'])
    );
  }

  /**
   * 订单是否作废
   * @returns {boolean}
   */
  isVoidTrade() {
    return this.order.getIn(['tradeState', 'flowState']) == 'VOID';
  }

  /**
   * 订单作废原因
   * @returns {any|string}
   */
  obsoleteReason() {
    return this.order.getIn(['tradeState', 'obsoleteReason']) || '-';
  }

  /**
   * 订单下单时间
   * @returns {any}
   */
  createTime() {
    const creat = this.order.getIn(['tradeState', 'createTime']);
    if (creat) {
      return _.formatDate(creat);
    }
  }

  /**
   * 买家信息
   * @returns {any}
   */
  buyerName() {
    return this.order.getIn(['consignee', 'name']);
  }

  buyerPhone() {
    return this.order.getIn(['consignee', 'phone']);
  }

  buyerAddress() {
    const consignee = this.order.get('consignee');
    return consignee ? consignee.get('detailAddress') : '';
  }

  /**
   * 购买人ID
   * @returns {any}
   */
  buyerId() {
    return this.order.getIn(['buyer', 'id']);
  }

  isPayed() {
    return this.order.getIn(['tradeState', 'payState']) != 'NOT_PAID';
  }

  totalPrice() {
    return _.addZero(this.order.getIn(['tradePrice', 'totalPrice']));
  }

  goodsPrice() {
    return _.addZero(this.order.getIn(['tradePrice', 'goodsPrice']));
  }

  /**
   * 满减金额
   */
  reductionPrice() {
    const discountsPriceDetails = this.order.getIn([
      'tradePrice',
      'discountsPriceDetails'
    ]);
    const reduction = discountsPriceDetails
      ? discountsPriceDetails.find((item) => item.get('marketingType') == 0)
      : null;
    return reduction ? _.addZero(reduction.get('discounts')) : 0;
  }

  /**
   * 满折金额
   */
  discountPrice() {
    const discountsPriceDetails = this.order.getIn([
      'tradePrice',
      'discountsPriceDetails'
    ]);
    const discount = discountsPriceDetails
      ? discountsPriceDetails.find((item) => item.get('marketingType') == 1)
      : null;
    return discount ? _.addZero(discount.get('discounts')) : 0;
  }

  /**
   * 优惠券金额
   * @returns {*}
   */
  couponPrice() {
    const couponPrice = this.order.getIn(['tradePrice', 'couponPrice']);
    return couponPrice ? _.addZero(couponPrice) : 0;
  }

  deliveryPrice() {
    return _.addZero(this.order.getIn(['tradePrice', 'deliveryPrice']));
  }

  buyerRemark() {
    return this.order.get('buyerRemark') || '无';
  }

  sellerRemark() {
    return this.order.get('sellerRemark') || '无';
  }

  tradeItems() {
    return this.order.get('tradeItems') || fromJS([]);
  }

  gifts() {
    return this.order.get('gifts') || fromJS([]);
  }

  totalNum() {
    return this.tradeItems()
      ? this.tradeItems().reduce((x, y: IMap) => (x += y.get('num')), 0)
      : 0;
  }

  /**
   * 订单附件
   * @returns {any[]}
   */
  encloses() {
    if (this.order.get('encloses')) {
      let encloses = this.order.get('encloses').split(',');
      let enclo = fromJS(encloses || []);
      return enclo.size > 0
        ? enclo.map((value) => Map().set('image', value)).toJS()
        : Array();
    } else {
      return Array();
    }
  }

  payId() {
    return this.order.getIn(['payInfo', 'payTypeId']);
  }

  orderPayState() {
    return PAY_STATUS[this.order.getIn(['tradeState', 'payState'])];
  }

  orderDeliveryState() {
    return DELIVERY_STATUS[this.order.getIn(['tradeState', 'deliverStatus'])];
  }

  orderInvoice() {
    return invoiceType(this.order.getIn(['invoice', 'type']));
  }

  /**
   * 店铺信息
   * @returns {string}
   */
  storeName() {
    return this.order.getIn(['supplier', 'storeName']);
  }

  storeId() {
    return this.order.getIn(['supplier', 'storeId']);
  }

  isSelf() {
    return this.order.getIn(['supplier', 'isSelf']);
  }

  platform() {
    return this.order.get('platform');
  }

  /**
   * 分销信息
   */
  // 邀请人ID
  getInviteeId() {
    return this.order.get('inviteeId');
  }
  // 邀请人名称
  distributorName() {
    return this.order.get('distributorName');
  }
  // 小店名称
  shopName() {
    return this.order.get('shopName');
  }
  // 分销渠道
  channelType() {
    return this.order.get('channelType');
  }
  // 订单返利
  commission() {
    let commission = this.order.get('commission');
    return commission ? _.addZero(commission) : 0;
  }
  // 是否是开店礼包
  storeBagsFlag() {
    return this.order.get('storeBagsFlag');
  }

  /**
   * 西兰豆兑换金额
   * @returns {string}
   */
  pointsPrice() {
    const pointsPrice = this.order.getIn(['tradePrice', 'pointsPrice']);
    return pointsPrice ? _.addZero(pointsPrice) : 0;
  }

  /**
   * 订单西兰豆
   */
  points() {
    return this.order.getIn(['tradePrice', 'points']);
  }
}

export default (order: IMap) => {
  return new WrapperOrder(order);
};
