import React from 'react'
import {noop} from 'wmkit'

/**
 * 公共check
 */
export default class Switch extends React.PureComponent<any, any> {

  static defaultProps = {
    switched: false,
    onSwitch: noop,
    style: {},
    disabled: false
  }

  render() {
    const {switched, onSwitch, style, disabled} = this.props

    return (
      <div style={{...styles.switch, ...style}} onClick={disabled ? noop : onSwitch}>
        <div className={(switched && !disabled ) ? 'label-switch-on' : 'label-switch-off'}>
        </div>
      </div>
    )
  }
}


const styles = {
  switch: {
    display: 'flex'
  }
}