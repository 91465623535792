import React, { Component } from 'react';
import { Relax } from 'plume2';
import { Button, noop, WMkit } from 'wmkit';
const TimerButton = Button.Timer;

@Relax
export default class DynamicFrom extends Component<any, any> {
  props: {
    relaxProps?: {
      account: string;
      verificationCode: string;
      showPass: Function;
      doLogin: Function;
      setAccount: Function;
      setVerificationCode: Function;
      sendCode: Function;
    };
  };

  static relaxProps = {
    account: 'account',
    verificationCode: 'verificationCode',
    doLogin: noop,
    setAccount: noop,
    setVerificationCode: noop,
    sendCode: noop
  };

  render() {
    let {
      setAccount,
      account,
      verificationCode,
      doLogin,
      setVerificationCode
    } = this.props.relaxProps;
    if (account.length > 11) {
      account = account.substring(0, 11);
    }
    return (
      <div style={{ marginTop: 25, width: '75%' }}>
        <div className="login-item">
          <div className="inputBox">
            <input
              className="formInput"
              type="tel"
              pattern="[0-9]*"
              placeholder="请输入您的手机号"
              value={account}
              maxLength={11}
              onChange={(e) => setAccount(e.target.value)}
            />
          </div>
        </div>
        <div className="login-item">
          <div className="inputBox" style={{ paddingRight: 0 }}>
            <input
              className="formInput sendCodeInput"
              type="text"
              placeholder="请输入短信验证码"
              pattern="/^[0-9]{6}$/"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              maxLength={6}
            />
            <TimerButton
              text="发送验证码"
              resetWhenError={true}
              shouldStartCountDown={() => this._beforeSendCode(account)}
              onClick={() => this._sendCode(account)}
              defaultStyle={{
                color: '#000',
                fontSize: '0.28rem',
                borderColor: 'transparent',
                borderLeftColor: '#e5e5e5',
                padding: 0,
                lineHeight: '0.3rem',
                height: '0.3rem',
                paddingLeft: '9px'
              }}
            />
          </div>
        </div>
        <div className="l-findCode" />
        <div className="login-btnBox">
          <button className="login-btn" onClick={() => doLogin()}>
            登录
          </button>
        </div>
      </div>
    );
  }

  /**
   * 发送验证码前校验手机号是否填写或正确
   * @returns {boolean}
   * @private
   */
  _beforeSendCode = (account) => {
    return WMkit.testTel(account);
  };

  /**
   * 发送验证码
   * @private
   */
  _sendCode = (account) => {
    const { sendCode } = this.props.relaxProps;
    return sendCode(account);
  };
}
