import noop from './noop';
import AsyncRouter from './async-router';
import routeWithSubRoutes from './route-with-subroutes';
import Slider from './slider';
import WMImage from './image-util/image';
import history from './history';
import * as FindArea from './area/area';
import Check from './check';
import * as WMkit from './kit';
import * as VAS from './vas';
import Fetch from './fetch';
import { Modal, Alert, Confirm } from './modal';
import * as GA from './ga';
import ListView from './list-view';
import Blank from './blank';
import PointsBlank from './pointsblank';
import NumberInput from './num-input';
import AddCart from './add-cart';
import Switch from './switch';
import * as Button from './button';
import Radio from './radio';
import * as _ from './common/util';
import UploadImage from './upload/upload-image';
import FormSelect from './form/form-select';
import FormInput from './form/form-input';
import FormItem from './form/form-item';
import FormRegexUtil from './form/form-regex';
import Picker from './picker';
import ProgressBar from './progress';
import Tabs from './tabs';
import storage from './storage';
import RadioBox from './radio-box';
import Tips from './tips';
import RadioHook from './radio-hook';
import Loading from './loading';
import WmLoading from './loading2';
import GiftList from './gift-list';
import SkuList from './goods-list';
import WMWholesaleChoose from './goods-choose/wholesale-choose';
import WMRetailChoose from './goods-choose/retail-choose';
import WMGrouponChoose from './goods-choose/groupon-choose';
import FormText from './form/form-text';
import OrderWrapper from './order-wrapper';
import ImageListScroll from './image-list-scroll';
import ValidConst from './validate';
import WMLoginModal from './login-modal';
import WMVideo from './video';
import NormalVideo from './video/video';
import LevelTag from './level-tag';
import { wxShare } from './weixin/public-platform/wx-share';
import * as wxAuth from './weixin/public-platform/wx-auth';
import CouponLabel from './coupon-label';
import Star from './star';
import CountDown from './count-down';
import GrouponBottom from './groupon-bottom';
import ReactIScroll from './reactjs-iscroll/src/scripts';
import GenderPicker from './gender-picker';
import BirthDayPicker from './birthday-picker';
import PhysiquePicker from './physique-picker';
import * as FindBusiness from './business/business';
import BusinessIndustryPicker from './business-industy-picker';
import BusinessEmployeeNumPicker from './business-employee-num-picker';

export {
  noop,
  AsyncRouter,
  routeWithSubRoutes,
  Slider,
  WMImage,
  history,
  FindArea,
  WMkit,
  VAS,
  Fetch,
  Modal,
  Alert,
  Confirm,
  GA,
  ListView,
  Blank,
  PointsBlank,
  Check,
  NumberInput,
  Switch,
  Button,
  Radio,
  FormSelect,
  FormInput,
  AddCart,
  FormItem,
  FormRegexUtil,
  UploadImage,
  _,
  Tabs,
  Picker,
  ProgressBar,
  storage,
  RadioBox,
  Tips,
  RadioHook,
  Loading,
  WmLoading,
  FormText,
  GiftList,
  SkuList,
  WMWholesaleChoose,
  WMRetailChoose,
  WMGrouponChoose,
  OrderWrapper,
  ImageListScroll,
  ValidConst,
  WMLoginModal,
  WMVideo,
  NormalVideo,
  LevelTag,
  wxShare,
  wxAuth,
  CouponLabel,
  Star,
  CountDown,
  GrouponBottom,
  ReactIScroll,
  GenderPicker,
  BirthDayPicker,
  PhysiquePicker,
  FindBusiness,
  BusinessIndustryPicker,
  BusinessEmployeeNumPicker,
};
