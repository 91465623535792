import { config } from 'config'

interface formatArgs {
  src: string,
  width?: string,
  height?: string,
  //https://help.aliyun.com/document_detail/44688.html?spm=5176.doc44704.6.926.UIEjnA
  /**
   *  lfit：等比缩放，限制在设定在指定w与h的矩形内的最大图片。
      mfit：等比缩放，延伸出指定w与h的矩形框外的最小图片。
      fill：固定宽高，将延伸出指定w与h的矩形框外的最小图片进行居中裁剪。
      pad：固定宽高，缩略填充
      fixed：固定宽高，强制缩略
   */
  mode?: string, // 裁剪模式
  formatToJPG?: boolean,
  devicePixelRatio?: number, //像素比
  imageServer?: string //自定义域名
}

/**
   * 包装地址
   * @param src
   * @private
   */

const wrapUrl = (params: formatArgs) => {
  let defaultParams = {
    alt: '',
    formatToJPG: true,
    src: '',
    devicePixelRatio: 1.5,
    mode: 'fill' //固定宽高，将延伸出指定w与h的矩形框外的最小图片进行居中裁剪。这样显示出来的最好,
  }
  params = Object.assign({}, defaultParams, params);
  let { src, formatToJPG, imageServer } = params;

  // 本地图片 或者 地址中已包含?参数，这种不是通过本系统上传的图片不进行处理
  if (src.indexOf('data') == 0 || src.indexOf('?') > 0) {
    return src
  }

  const isComp = src.indexOf('http') >= 0;
  src = `${isComp ? '' : `${imageServer ? imageServer : config.IMAGE_SERVER}`}${src}`;

  params.src = cleanUrl(src);

  if (canUseWebp()) {
    return format("webp", params);
  } else if (formatToJPG) {
    return format("", params)
  } else {
    return src;
  }
}

const cleanUrl = (src: string = ''): string => {
  let p = src.lastIndexOf("@");
  return p != -1
    ? src.substring(0, p)
    : src
}

/**
 * 浏览器是否支持webp
 * @see http://stackoverflow.com/questions/5573096/detecting-webp-support
 */
const canUseWebp = (): boolean => {
  if (window['canUseWebp'] == undefined) {
    return window['canUseWebp'] = (() => {
      let elem = document.createElement('canvas');
      if (!!(elem.getContext && elem.getContext('2d'))) {
        // was able or not to get WebP representation
        return elem
          .toDataURL('image/webp')
          .indexOf('data:image/webp') == 0;
      } else {
        // very old browser like IE 8, canvas not supported
        return false;
      }
      // let webpImg = new Image;
      // webpImg.onload = function () {
      //   if (webpImg.width == 1) {
      //     alert('支持')
      //   }
      // };
      // webpImg.src = 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAgA0JaQAA3AA/vv9UAA=';
    })();
  }
  return window['canUseWebp'];
}

const format = (formatType, params: formatArgs) => {
  let {
    src,
    mode,
    width,
    height,
    formatToJPG,
    devicePixelRatio } = params;

  let f = [src, "?x-oss-process=image"];
  f.push('/resize');
  if (mode) {
    f.push(`,m_${mode}`)
  }
  if (width || height) {
    if (width) {
      f.push(`,w_${Math.round(parseInt(width) * devicePixelRatio)}`)
    }
    if (height) {
      f.push(`,h_${Math.round(parseInt(height) * devicePixelRatio)}`)
    }
  } else {
    f.push(`,w_960`)
  }
  //加入锐化效果，增加清晰度
  f.push('/sharpen,100')
  if (formatType) {
    f.push(`/format,${formatType}`)
  }
  return f.join('')
}

export default wrapUrl;