import React, { Component } from 'react';
import { Relax } from 'plume2';
import { noop } from 'wmkit';
@Relax
export default class LoginTab extends Component<any, any> {
  props: {
    relaxProps?: {
      isALogin: boolean;
      loginChange: Function;
    };
  };

  static relaxProps = {
    isALogin: 'isALogin',
    loginChange: noop
  };

  render() {
    const { isALogin, loginChange } = this.props.relaxProps;
    return (
      <div className="loginSwitch">
        <span
          onClick={() => (isALogin ? {} : loginChange())}
          className={isALogin ? 'curr' : undefined}
        >
          账号登录
        </span>
        <span
          onClick={() => (!isALogin ? {} : loginChange())}
          className={!isALogin ? 'curr' : undefined}
        >
          动态码登录
        </span>
      </div>
    );
  }
}
