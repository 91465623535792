import React, { Component } from 'react'
import { noop } from 'wmkit'


export interface DialogProps {
  title?: String,
  content?: any,
  btnText?: String,
  btnCb?: () => void,
  close?: () => void,
  cb?: () => void
}

export default class Dialog extends Component<DialogProps, any> {

  static defaultProps = {
    title: '',
    content: <div>渲染区域</div>,
    btnText: '',
    btnCb: noop,
    cb: noop
  }

  render() {
    const { title, content, btnText, btnCb, close, cb } = this.props;
    return (
      <div className="poper">
        <div className="mask"></div>
        <div className="poper-content">
          <div className="poper-title">
            {title}
          </div>
          <div>
            {content}
          </div>
          {
            btnText ?
              <button onClick={() => { btnCb(); close() }} className="button button-box">
                {btnText}
              </button>
              : null
          }
        </div>
      </div>
    )
  }
}
