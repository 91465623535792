import React from 'react';
import { WMkit } from 'wmkit';

const noneImg = require('./img/none.png');
import * as _ from '../common/util';
import {isFlashSale} from "../../../src/goods-detail/webapi";

const IS_ACCOUNT_STATUS = {
  0: '待入账',
  1: '已入账',
  2: '入账失败'
};

export default class SkuList extends React.PureComponent<any, any> {
  static defaultProps = {
    //样式
    style: {},
    //选项数据
    data: [],
    // 是否自营
    isSelf: false,
    //是否显示返利
    rebate: false,
    // 返利图标字样(0:赚 1:返)
    commissionStrType: 0,
    // 是否显示返利图标
    showCommissionStr: true
  };

  render() {
    const { style, data } = this.props;
    return (
      <div style={{ background: '#fafafa', ...style }}>
        {data.map((sku) => this._buildSkuItem(sku))}
      </div>
    );
  }

  /**
   * 构建sku结构
   * @param sku
   * @returns {any}
   * @private
   */
  _buildSkuItem = (sku) => {
    const {
      imgUrl,
      skuName,
      specDetails,
      price,
      points,
      num,
      unit,
      distributionGoodsAudit,
      distributionCommission,
      pointsGoodsId,
      isAccountStatus
    } = sku;
    const { isSelf, rebate, commissionStrType, showCommissionStr } = this.props;
    let commissionFlag = false;
    if (WMkit.isDistributorLogin() && distributionGoodsAudit == 2) {
      // 小b登录时，分销商品显示返利
      commissionFlag = true;
    }
    return (
      <div className="goods-list">
        <img className="img-box" src={imgUrl ? imgUrl : noneImg} />
        <div className="detail b-1px-b">
          <div className="rowTitle">
            <a className="title " href="#">
              {skuName}
            </a>
            {(isAccountStatus || isAccountStatus == 0) && (
              <span className="isAccountStatus">
                {IS_ACCOUNT_STATUS[isAccountStatus]}
              </span>
            )}
          </div>
          <p className="gec">{specDetails ? specDetails : ''}</p>
          <div className="marketing">
            {!WMkit.isShop() &&
              !rebate &&
              isSelf == true && <div className="self-sales">自营</div>}
            {commissionFlag &&
              rebate && !pointsGoodsId && (
                <span className="rebate">
                  {showCommissionStr && (
                    <span className="rebate-bg">
                      {commissionStrType == 0 ? '赚' : '返'}
                    </span>
                  )}
                  <i className="iconfont icon-qian" />
                  {distributionCommission.toFixed(2)}
                </span>
              )}
            {/* <div className="tag">减</div>
            <div className="tag red">赠</div> */}
          </div>
          <div className="bottom">
            {pointsGoodsId ? (
              <span className="price">{points}西兰豆</span>
            ) : (
              <span className="price">
                <i className="iconfont icon-qian" />
                {_.addZero(price)}
              </span>
            )}
            <span className="num">
              ×{num}
              {unit ? unit : ''}
            </span>
          </div>
        </div>
      </div>
    );
  };
}
