import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IRoute } from './route-with-subroutes';
import noop from './noop';
import Loading from './loading';
import wx from 'weixin-js-sdk';
import { _, Alert, Fetch, history, storage, WMkit } from 'wmkit';
import { cache, config } from 'config';
import parse from 'url-parse';
import { mergePurchase } from 'biz';
import { msg } from 'plume2';

export type Loader = () => Promise<any>;

export interface Props {
  path: string;
  exact?: boolean;
  strict?: boolean;
  load: Loader;
  title?: string;
  subRoutes?: Array<IRoute>;
  handlePathMatched?: Function;
  hasBottom: boolean;
  withoutLogin?: boolean;
  openAccess?: boolean;
}

/**
 * 封装异步路由的解决方案
 * @param props 路由参数
 */
export default class AsyncRoute extends Component<Props, any> {
  constructor(props) {
    super(props);
    // 是否获取到开放访问的开关
    this.state = {
      getOpenSwitch: false,
      //监听微信授权重定向之后的事件是否执行完毕，让页面缓加载
      loginFlag: false
    };
  }

  componentWillMount() {
    //每次查询一下开放访问的状态，实时更新
    Fetch('/userSetting/isVisitWithLogin', {
      method: 'POST'
    }).then((res) => {
      if (res.code == config.SUCCESS_CODE) {
        window.needLogin = (res.context as any).audit;
        this.setState({ getOpenSwitch: true });
      }
    });

    // 若页面是在小程序webview中,则将token传给小程序
    wx.miniProgram.getEnv((res) => {
      if (res.miniprogram) {
        wx.miniProgram.postMessage({
          data: {
            type: 'sendToken',
            shareUserId: WMkit.getUserId(),
            token: window.token
          }
        });
      }
    });

    // 只有微信浏览器 并且 openId没有被缓存
    if (_.isWeixin() && !WMkit.wechatOpenId().openid) {
      // 1.获取mobile端-微信授权登陆开关状态
      Fetch('/pay/getAppId/WECHAT').then((res: any) => {
        if (res.code == config.SUCCESS_CODE) {
          // true:授权登陆开关打开
          if (res.context.appId) {
            // 2.获取微信支付接口的appid
            window.wechatConfig = {
              appId: res.context.appId
            };
            const {
              query: { state, code }
            } = parse(window.location.search, true);
            if (state && code) {
              if (state == 'b2bOpenId') {
                // 2.2.获取当前微信用户的openid,并缓存
                return Fetch(`/pay/getOpenId/WECHAT/${code}`).then((result) => {
                  if (result.code == config.SUCCESS_CODE) {
                    const openid = result.context;
                    storage('local').set(cache.AUTH_INFO, {
                      openid: openid
                    });
                  } else {
                    console.log('get openid error:', result.message);
                  }
                });
              }
            } else {
              // 2.1.通过appid获取code(code用于获取openId,微信使用重定向方式传入code给当前路由)
              WMkit.wechatGetOpenId({
                redirectURL: window.location.href
              });
            }
          }
        }
      });
    }

    //点击弹框中的授权登录，重定向以后进行的处理
    const {
      query: { state, code }
    } = parse(window.location.search, true);
    //非静默授权时，且openId存在，防止跟上面的逻辑重复，只对登录部分进行处理，绑定页过来的，不走
    if (
      state &&
      state != 'b2bOpenId' &&
      (state.indexOf('login') != -1 || state.indexOf('deposit') != -1) &&
      WMkit.wechatOpenId().openid
    ) {
      //点击微信登录，会进行重定向，此时页面被刷新，state发生变化，走微信授权逻辑
      if (state.indexOf('login') != -1) {
        Fetch('/third/login/wechat/auth', {
          method: 'POST',
          body: JSON.stringify({ code: code, channel: 'MOBILE' })
        }).then((res) => {
          if (res.code == config.SUCCESS_CODE) {
            const { loginFlag, login, info } = res.context as any;
            // loginFlag 为true时表示走登录流程，false时跳转到绑定页面
            if (loginFlag) {
              WMkit.switchModalLogin(login);
              this.setState({
                loginFlag: true
              });
              // 登录成功，发送消息，查询分销员信息 footer/index.tsx
              msg.emit('userLoginRefresh');
              // 登陆成功,需要合并登录前和登陆后的购物车,传入回调函数
              mergePurchase(null);
            } else {
              history.push({
                pathname: '/wechat-login',
                state: { info }
              });
            }
          } else {
            history.push({
              pathname: '/'
            });
          }
        });
      } else {
        //提现授权，获取用户信息
        Fetch('/third/login/wechat/deposit/auth', {
          method: 'POST',
          body: JSON.stringify({ code: code, channel: 'MOBILE' })
        }).then((res) => {
          if (res.code == config.SUCCESS_CODE) {
            const { name, headImgUrl } = res.context as any;
            const { openid } = WMkit.wechatOpenId();
            storage('local').set(cache.AUTH_INFO, {
              openid: openid,
              name: name,
              headImgUrl: headImgUrl
            });
            history.push({
              pathname: '/balance/applicat-form'
            });
          } else {
            history.push({
              pathname: '/'
            });
          }
        });
      }
    }
    WMkit.testToken();
  }

  render() {
    const {
      query: { state, code }
    } = parse(window.location.search, true);
    //1.开放访问开关状态没获取到
    //2.微信授权重定向以后的事件没有执行完，这两种情况给出loading效果
    if (
      !this.state.getOpenSwitch ||
      (state &&
        state.indexOf('login') != -1 &&
        WMkit.wechatOpenId().openid &&
        !this.state.loginFlag)
    ) {
      return <Loading />;
    }
    const {
      load,
      subRoutes,
      title,
      handlePathMatched,
      withoutLogin,
      openAccess,
      hasBottom,
      ...rest
    } = this.props;
    return (
      <Route
        {...rest}
        render={(props) => {
          // 1.已经登录
          if (WMkit.isLogin()) {
            if (props.location.pathname == '/login') {
              return (
                <Redirect
                  to={{
                    pathname: '/',
                    state: { from: props.location }
                  }}
                />
              );
            } else {
              return (
                <AsyncLoader
                  {...props}
                  load={load}
                  subRoutes={subRoutes}
                  title={title}
                  handlePathMatched={handlePathMatched}
                  hasBottom={hasBottom}
                />
              );
            }
          }

          // 2.完全不需要登录的路由,正常跳转
          if (withoutLogin) {
            return (
              <AsyncLoader
                {...props}
                load={load}
                subRoutes={subRoutes}
                title={title}
                handlePathMatched={handlePathMatched}
                hasBottom={hasBottom}
              />
            );
          }
          // 3.平台不开放访问(需要登录),跳转登录页
          if (window.needLogin) {
            sessionStorage.setItem(
              cache.TARGET_PAGES,
              JSON.stringify(props.location)
            );
            return (
              <Redirect
                to={{
                  pathname: '/login',
                  state: { from: props.location }
                }}
              />
            );
          } else {
            if (openAccess) {
              // 4.1.开放访问(不需要登录),配置了openAccess属性的路由,可直接访问
              return (
                <AsyncLoader
                  {...props}
                  load={load}
                  subRoutes={subRoutes}
                  title={title}
                  handlePathMatched={handlePathMatched}
                  hasBottom={hasBottom}
                />
              );
            } else {
              sessionStorage.setItem(
                cache.TARGET_PAGES,
                JSON.stringify(props.location)
              );
              // 4.2.开放访问(不需要登录),未配置openAccess属性的路由,跳转登录页
              return (
                <Redirect
                  to={{
                    pathname: '/login',
                    state: { from: props.location }
                  }}
                />
              );
            }
          }
        }}
      />
    );
  }
}

/**
 * 异步load模块组件
 */
class AsyncLoader extends Component<any, any> {
  props: {
    load: Loader;
    subRoutes: Array<IRoute>;
    title?: string;
    location?: any;
    match?: any;
    handlePathMatched?: Function;
    hasBottom?: boolean;
  };

  state: {
    Component: React.ComponentClass<any>;
  };

  static defaultProps = {
    load: noop
  };

  constructor(props) {
    super(props);
    this.state = {
      Component: null
    };
  }

  componentDidMount() {
    const { load, title, hasBottom, match } = this.props;
    const { handlePathMatched } = this.props;
    handlePathMatched({ path: match.path, hasBottom: hasBottom });

    load()
      .then((Component) =>
        this.setState({
          Component: Component.default || Component
        })
      )
      .catch((err) => {
        if (process.env.NODE_ENV != 'production') {
          console.trace(err);
        }
      });
    if (title) {
      document.title = title;
    }
  }

  render() {
    const { Component } = this.state;

    return Component ? <Component {...this.props} /> : <Loading />;
  }
}
