import React from 'react'


export interface IFormItemProps{
  labelName?: string
  placeholder?: string | React.ReactElement<any>
  // 输入框样式
  textStyle?: Object
}


/**
 * form中普通展示项
 */
export default class FormItem extends React.Component<IFormItemProps, any> {
  constructor(props: IFormItemProps) {
    super(props)
  }

  render() {
    const {labelName, placeholder, textStyle} = this.props
    return (
      <div className="row form-item">
        <span className="form-text">{labelName}</span>
        <div className="form-context">
          <span className="select-text" style={textStyle}>{placeholder}</span>
        </div>
      </div>
    )
  }
}
