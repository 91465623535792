import { config } from 'config';
/**
 * pv/uv收集埋点
 * Created by bail on 2017/9/26.
 */
var sendUrl = config.PV_UV_HOST + '/wm.gif';
var clientType = 'H5';

function getUserId() {
  var userId = null;
  var itemStr = localStorage.getItem('b2b-wechat@login');
  if (itemStr) {
    userId = JSON.parse(itemStr).customerId; //获取userId,HTML5写法,不支持低版本浏览器
  }
  return userId;
}

function uuid() {
  var s = [];
  var hexDigits = '0123456789abcdef';
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = '-';
  return s.join('');
}

function getCookieValue(a) {
  try {
    a += '=';
    var b = document.cookie,
      c = b.indexOf(a),
      d = '';
    if (-1 < c) {
      var e = b.indexOf(';', c);
      -1 === e && (e = b.length);
      d = b.substring(c + a.length, e);
    }
    return d ? d : '';
  } catch (n) {
    return 'cookieDisabled';
  }
}

function getSendId() {
  var sendId = getUserId();
  if (!sendId) {
    sendId = getCookieValue('WM_UUID'); //userId不存在,获取cookie中的uuid
    if (!sendId) {
      sendId = uuid(); //uuid不存在,则在cookie中创建一个uuid
      var e = new Date();
      e.setTime(e.getTime() + 157248e5); //半年
      document.cookie = 'WM_UUID=' + sendId + '; expires=' + e.toUTCString();
      +'; path=/';
    }
  }
  return sendId;
}

function getXmlHttpRequest() {
  if (window.XMLHttpRequest) {
    return new XMLHttpRequest();
  } else if (window.ActiveXObject) {
    return new ActiveXObject('Microsoft.XMLHttpRequest');
  }
}

function myAjaxPost(url, dataStr) {
  var xhr = getXmlHttpRequest();
  xhr.onreadystatechange = function() {
    if (xhr.readyState === 4 && xhr.status === 200) {
      //xhr.responseText;
    }
  };
  xhr.open('post', url, true);
  xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
  xhr.send(dataStr);
}
// kstore2.0版本使用该方法
// window.onload = function(){
//   myAjaxPost(sendUrl,"id="+getSendId()+"&url="+location.href+"&clientType="+clientType);
// }

// 3.0版本使用该方法
window.myWmHref = null; //记录刚刚访问的链接,默认为null
window.myPvUvStatis = function(skuId, shopId) {
  if (window.myWmHref != location.href) {
    window.myWmHref = location.href;
    myAjaxPost(
      sendUrl,
      'id=' +
        getSendId() +
        '&url=' +
        location.href +
        '&clientType=' +
        clientType +
        '&skuId=' +
        (skuId || '') +
        '&shopId=' +
        (shopId || '')
    );
  }
};
