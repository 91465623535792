import React from 'react';
import { msg, StoreProvider } from 'plume2';
import AppStore from './store';
import Register from './components/register';
import EnterpriseRegister from './components/enterprise-register';
import LoginTab from './components/login-tab';
import QuickLogin from './components/quick-login';
import AccountFrom from './components/account-form';
import DynamicFrom from './components/dynamic-from';
import Agreement from './components/agreement';
import EnterpriseAgreement from './components/enterprise-agreement';
import './css/style.css';
import { WMkit } from 'wmkit/index';

const logo = require('./img/logo.png');

let u = navigator.userAgent;
let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //这个判断 是不是ios手机

@StoreProvider(AppStore, { debug: __DEV__ })
export default class WMLoginModal extends React.Component<any, any> {
  store: AppStore;

  //控制弹框显示隐藏
  componentWillMount() {
    msg.on('loginModal:toggleVisible', this.toggleVisible);
  }

  //组件销毁事件解绑
  componentWillUnmount() {
    msg.off('loginModal:toggleVisible', this.toggleVisible);
  }

  componentDidUpdate() {
    if (isiOS) {
      //ios手机上才会出现input失去焦点的时候页面被顶起
      window.addEventListener('scroll', this._initScroll);
    }
  }

  constructor(props) {
    super(props);
  }

  render() {
    const showLogin = this.store.state().get('showLogin');
    const isALogin = this.store.state().get('isALogin');
    const pcLogo = this.store.state().get('pcLogo');
    const wxFlag = this.store.state().get('wxFlag');
    const showAgreement = this.store.state().get('showAgreement');
    const cancelCallBack = this.store.state().get('cancelCallBack');
    const iepFlag = this.store.state().get('iepFlag');
    const showEnterpriseAgreement = this.store
      .state()
      .get('showEnterpriseAgreement');
    const showEnterprise = this.store.state().get('showEnterprise');
    return (
      this.store.state().get('modalVisible') && (
        <div className="login-modal" id="login-modal">
          {showLogin ? (
            <div className="loginContainer">
              <a
                // href="javascript:void(0);"
                className="closeBox"
                onClick={() => {
                  this.store.toggleVisible({ visible: false, callBack: null });
                  if (cancelCallBack) {
                    cancelCallBack();
                  }
                }}
              >
                <i className="iconfont icon-guanbi" />
              </a>
              <div className="l-logo">
                <img src={pcLogo ? pcLogo : logo} className="logoImg" />
              </div>
              <LoginTab />
              {isALogin ? <AccountFrom /> : <DynamicFrom />}
              <div className="register-account">
                <a onClick={() => this.store.toggleLogin()}>
                  <span className="dark-text">注册账号</span>
                  <i
                    style={{ marginLeft: 5, fontSize: 12 }}
                    className="iconfont icon-xiayibu1 dark-text"
                  />
                </a>
              </div>
              <div className="bottomBar">
                {/* {wxFlag && <QuickLogin />} */}
                <div className="footer-copyright">
                  <p>© 2019-2021 上海西兰蒂亚科技有限公司</p>
                </div>
              </div>
            </div>
          ) : showEnterprise ? (
            <EnterpriseRegister />
          ) : (
            <Register />
          )}
          {showAgreement && <Agreement />}
          {showEnterpriseAgreement && <EnterpriseAgreement />}
        </div>
      )
    );
  }

  toggleVisible = ({ callBack, cancelCallBack }) => {
    this.store.init(cancelCallBack);
    this.store.toggleVisible({ visible: true, callBack: callBack });
  };

  /**
   * IOS端点击input键盘收起的事件处理
   * @private
   */
  _initScroll = () => {
    let loginModal = document.getElementById('login-modal');
    if (loginModal) {
      loginModal.addEventListener('focusout', () => {
        //软键盘收起的事件处理
        setTimeout(window.scrollTo(0, 0), 500);
      });
    }
  };
}
