import React from 'react';
import { Relax } from 'plume2';
import { Link } from 'react-router-dom';
import { history } from 'wmkit';

export default class GrouponSearch extends React.Component<any, any> {
  render() {
    const { keyWords } = this.props;

    return (
      <div className="search-box">
        <div
          className="input-box"
          onClick={() =>
            history.push({
              pathname: '/search',
              state: { queryString: keyWords, key: 'groupon' }
            })
          }
        >
          <i className="iconfont icon-sousuo" />
          <input type="text" value={keyWords} placeholder="请输入搜索关键词" />
        </div>
        <div />
      </div>
    );
  }
}
