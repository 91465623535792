import React, { Component } from 'react';

const borderLeft = require('./img/borderLeft.png')
const borderRight = require('./img/borderRight.png')


/**
 * 公共等级标签组件
 */
export default class LevelTag extends Component<any, any> {
  props: {
    text?: string;
    style?: {};
  }

  render() {
    const { text, style } = this.props;
    return (
      <div className="level-tag" style={style}>
        <img className="border-left" src={borderRight} alt=""/>
        <span>{text}</span>
        <img className="border-right" src={borderLeft} alt=""/>
      </div>
    )
  }
}