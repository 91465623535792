import React from 'react';
import { noop } from 'wmkit';
import './css/style.css';

export default class NewLayer extends React.Component<any, any> {
  static defaultProps = {
    _closeDetail: noop,
    image: ''
  };

  render() {
    const { detailData, _closeDetail, image } = this.props;

    return (
      <div className="detail-div">
        <div className="scroll-div">
          <div className="white-div">
            <div
              className="detail-text"
              dangerouslySetInnerHTML={{
                __html: detailData
              }}
            />
          </div>
        </div>
        <div className="close" onClick={() => _closeDetail()}>
          <i className="iconfont icon-Close f-style" />
        </div>
      </div>
    );
  }
}
