import React, { Component } from 'react';
import { Relax } from 'plume2';
import {
  FormInput,
  Button,
  noop,
  ValidConst,
  Alert,
  PhysiquePicker
} from 'wmkit';

const LongBlueButton = Button.LongBlue;
const SubmitButton = Button.LongBlue;
const TimerButton = Button.Timer;

import '../css/style.css';
import EnterpriseFile from './enterprise-file'
import FormSelectReq from 'wmkit/form/form-select-required';
@Relax
export default class EnterpriseRegister extends Component<any, any> {

  static relaxProps = {
    toggleVisible: noop,
    initPage: noop,
    setFieldValue: noop,
    sendCode: noop,
    doEnterpriseRegister: noop,
    toggleShowAgreement: noop,
    registerAccount: 'registerAccount',
    registerPass: 'registerPass',
    registerCode: 'registerCode',
    showRegisterPass: 'showRegisterPass',
    //邀请人
    inviteeCustomerName: 'inviteeCustomerName',
    //显示企业账户信息
    showEnterpriseMoreInfo: 'showEnterpriseMoreInfo',
    toggleEnterprise:noop,
    showEnterprise:'showEnterprise',
    toggleBackEnterprise:noop,
    enterpriseInfoVO:'enterpriseInfoVO',
    setEnterpriseFieldValue:noop,
    toggleShowEnterpriseAgreement:noop,
    doEnterpriseSubmit:noop,
    enterpriseCheckState:'enterpriseCheckState',
    enterpriseCheckTip:'enterpriseCheckTip',
    toggleBackLogin:noop,
    registerLimitType:'registerLimitType',
    openFlag:'openFlag',
    sendEnterpriseRegisterCode:noop
  };

  render() {
    const {
      registerAccount,
      registerPass,
      registerCode,
      setFieldValue,
      showRegisterPass,
      sendCode,
      doEnterpriseRegister,
      inviteeCustomerName,
      showEnterpriseMoreInfo,
      toggleShowEnterpriseAgreement,
      enterpriseInfoVO,
      setEnterpriseFieldValue,
      doEnterpriseSubmit,
      enterpriseCheckState,
      enterpriseCheckTip,
      toggleBackLogin,
      registerLimitType,
      openFlag,
      sendEnterpriseRegisterCode
    } = this.props.relaxProps;

    let businessNatureName = enterpriseInfoVO.get('businessNatureType') == 1 ? '政府机关/事业单位' :
      enterpriseInfoVO.get('businessNatureType') == 2 ? '国营' :
        enterpriseInfoVO.get('businessNatureType') == 3 ? '私营':
          enterpriseInfoVO.get('businessNatureType') == 4 ? '中外合资':
            enterpriseInfoVO.get('businessNatureType') == 5 ? '外资':
              enterpriseInfoVO.get('businessNatureType') == 6 ? '其他': '请选择';

    let disabledFlag = enterpriseCheckState == 1;

    let tipFlag = enterpriseCheckState == 1|| enterpriseCheckState == 3;
    let tipMessage = disabledFlag? '您提交的账户信息正在审核中，请耐心等待。' : '您提交的账户信息审核未通过！';
    let disabledStyle = disabledFlag? {color:'grey'}:{};

    //对应显示的样式
    let textStyle = businessNatureName && !disabledFlag ? { color: '#333' } : {color:'grey'};
    const { toggleEnterprise,toggleBackEnterprise } = this.props.relaxProps;

    return (
      <div className="registerContainer">

        {!showEnterpriseMoreInfo ? (
          <>
            <div className="loginContainer-header">
              <a
                href="javascript:void(0);"
                onClick={() => {
                  toggleEnterprise()
                }}
                className="closeBox"
              >
                <i className="iconfont icon-jiantou" />
              </a>
            </div>
            <div style={{ width: '90%' }}>
              <h4 className="registerTitle">企业用户注册</h4>
              {inviteeCustomerName && (
                <p className="invite-text">邀请人：{inviteeCustomerName}</p>
              )}
              <div className="registerBox">
                <FormInput
                  label=""
                  type="tel"
                  placeHolder="请输入您的手机号"
                  pattern="[0-9]*"
                  defaultValue={registerAccount}
                  maxLength={11}
                  onChange={(e) =>
                    setFieldValue({
                      field: 'registerAccount',
                      value: e.target.value
                    })
                  }
                />
                <FormInput
                  label=""
                  type="number"
                  placeHolder="请输入验证码"
                  pattern="[0-9]*"
                  defaultValue={registerCode}
                  maxLength={6}
                  onChange={(e) =>
                    setFieldValue({
                      field: 'registerCode',
                      value: e.target.value
                    })
                  }
                  other={
                    <TimerButton
                      text="发送验证码"
                      resetWhenError={true}
                      shouldStartCountDown={() => this._sendCode()}
                      onClick={() => sendEnterpriseRegisterCode(registerAccount)}
                      defaultStyle={{
                        color: '#000',
                        borderColor: '#000'
                      }}
                    />
                  }
                />
                <div className="row form-item">
                  <div className="eyes-box">
                    <input
                      placeholder="请输入6-16位字母或数字密码"
                      type={showRegisterPass ? 'text' : 'password'}
                      value={registerPass}
                      pattern="/^[A-Za-z0-9]{6,16}$/"
                      className="form-input"
                      maxLength={16}
                      onChange={(e) =>
                        setFieldValue({
                          field: 'registerPass',
                          value: e.target.value
                        })
                      }
                    />
                    <i
                      onClick={() =>
                        setFieldValue({
                          field: 'showRegisterPass',
                          value: !showRegisterPass
                        })
                      }
                      className={`iconfont icon-${
                        showRegisterPass ? 'yanjing' : 'iconguanbiyanjing'
                      }`}
                    />
                  </div>
                </div>
                <div className="registerTips">
                  点击注册代表您已阅读并接受<a
                    href="javascript:;"
                    onClick={() => toggleShowEnterpriseAgreement()}
                  >
                    <span>《企业用户注册协议》</span>
                  </a>
                </div>
              </div>
              <div className="registerBtn">
                <LongBlueButton text="注册" onClick={() => doEnterpriseRegister()} />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="loginContainer-header">

              {/*指向两个地方  如果是初次注册  指向企业注册页面   如果是审核中或者审核不通过  指向 登录页*/}
              {
                enterpriseCheckState != '1' && enterpriseCheckState != '3' ? (
                  <a
                    href="javascript:void(0);"
                    onClick={() => {
                      toggleBackEnterprise()
                    }}
                    className="closeBox"
                  >
                    <i className="iconfont icon-jiantou" />
                  </a>
                ):(
                  <a
                    href="javascript:void(0);"
                    onClick={() => {
                      toggleBackLogin()
                    }}
                    className="closeBox"
                  >
                    <i className="iconfont icon-jiantou" />
                  </a>
                )
              }

            </div>
            <div className="improve-info">

              {
                tipFlag &&
                  <div style={{paddingBottom:'35px',fontSize: '14px'}}>
                    <div className="tip-box" style={{marginBottom:'10px'}}>
                      <span className="text-img">
                        <img src={require('../img/tip-01.jpg')} className="logoImg" style={{height:'45px'}}/>
                      </span>
                    </div>
                    <div className="tip-box" style={{marginBottom:'10px'}}>
                      <span className="text-img">
                        {tipMessage}
                      </span>
                    </div>
                    {!disabledFlag &&
                      <>
                        <div className="tip-box" style={{marginBottom:'10px'}}>
                          <span className="text-img">
                            {'原因是:'+enterpriseCheckTip}
                          </span>
                        </div>
                        <div className="tip-box">
                          <span className="text-img">
                            请您修改公司信息后重新提交
                          </span>
                        </div>
                      </>
                    }

                </div>
              }

              <div className="tip-box" style={{marginBottom:'5px'}}>
                <span className="big-text">
                  企业用户注册
                </span>
              </div>
              <div className="tip-box">
                <span className="text">
                  填写公司信息
                </span>
              </div>

              <PhysiquePicker
                extra="请选择"
                className="addr-picker"
                disabled={disabledFlag}
                style={disabledStyle}
                value={enterpriseInfoVO.get('businessNatureType')?enterpriseInfoVO.get('businessNatureType'):null}
                onOk={(val) => {
                  setEnterpriseFieldValue({
                    field: 'businessNatureType',
                    value: val
                  })
                }}
              >
                <FormSelectReq
                  labelName="公司性质"
                  placeholder="请选择"
                  textStyle={textStyle}
                  selected={{ key: enterpriseInfoVO.get('businessNatureType'), value: businessNatureName }}
                />
              </PhysiquePicker>
              <div className="row form-item">
                <span className="form-text">
                  <i>*</i>公司名称
                </span>
                <input
                  placeholder="请按营业执照填写"
                  type="text"
                  className="form-input background-white"
                  disabled={disabledFlag}
                  style={disabledStyle}
                  value={enterpriseInfoVO.get('enterpriseName')}
                  maxLength={60}
                  minLength={2}
                  onChange={(e) =>
                    setEnterpriseFieldValue({
                      field: 'enterpriseName',
                      value: e.target.value
                    })
                  }
                />
              </div>
              <div className="row form-item">
                <span className="form-text">
                  <i>*</i>统一社会信用代码
                </span>
                <input
                  placeholder="即纳税人识别号（税号）"
                  type="text"
                  className="form-input background-white"
                  style={disabledStyle}
                  value={enterpriseInfoVO.get('socialCreditCode')}
                  maxLength={30}
                  disabled={disabledFlag}
                  minLength={8}
                  onChange={(e) =>
                    setEnterpriseFieldValue({
                      field: 'socialCreditCode',
                      value: e.target.value
                    })
                  }
                />
              </div>

              <EnterpriseFile />

              {
                openFlag == 1  ?
                  (<div className="row form-item">
                    <span className="form-text">
                      {
                       registerLimitType == 1  &&  <i>*</i>
                      }
                     邀请码
                    </span>
                        <input
                          placeholder="请输入您获得的邀请码"
                          type="text"
                          className="form-input background-white"
                          style={disabledStyle}
                          value={enterpriseInfoVO.get('inviteCode')}
                          disabled={disabledFlag}
                          maxLength={15}
                          onChange={(e) =>
                            setEnterpriseFieldValue({
                              field: 'inviteCode',
                              value: e.target.value
                            })
                          }
                        />
                  </div>):null
              }

              {
                !disabledFlag &&
                <div className="improve-btn">
                  <SubmitButton text="提交" onClick={() => doEnterpriseSubmit()}>
                    提交
                  </SubmitButton>
                </div>
              }

            </div>
          </>
        )
        }
      </div>
    );
  }

  _sendCode = () => {
    const { registerAccount } = this.props.relaxProps;
    const regex = ValidConst.phone;
    if (!registerAccount) {
      Alert({
        text: '手机号不能为空！'
      });
      return false;
    } else if (!regex.test(registerAccount)) {
      Alert({
        text: '手机号格式有误!'
      });
      return false;
    } else {
      return true;
    }
  };
}
