import React, { Component } from 'react';

export default class AddCart extends Component<any, any> {
  props: {
    disableAdd?: boolean;
    skuId: string;
    spuAddCartFunc: Function;
  };

  render() {
    const { disableAdd, skuId, spuAddCartFunc } = this.props;
    return (
      <div
        className={disableAdd ? 'addCartBox disabledAdd' : 'addCartBox'}
        onClick={(e) => {
          if (!disableAdd) {
            spuAddCartFunc(skuId);
          }
          e.stopPropagation();
        }}
      >
        <div className="addCircle">
          <i className="iconfont icon-add11" />
        </div>
      </div>
    );
  }
}
