import React from "react"

const noneImg = require('../../images/none.png')

export default class GiftList extends React.PureComponent<any, any> {

  render() {
    const { data, shipMode } = this.props
    return data && data.length > 0 &&
      <div className="gift-box">
        <div className="gift">
          {data.map(item =>
            <div className="goods-list">
              <img className="img-box" src={item.pic ? item.pic : noneImg} />
              <div className="detail">
                <a className="title" href="#"><div className="tag-gift">赠品</div>{shipMode ? item.itemName : item.skuName}</a>
                <p className="gec">{item.specDetails ? item.specDetails : ''} </p>
                <div className="bottom">
                  {!shipMode && <span className="price"><i className="iconfont icon-qian" />0.00</span>}
                  <span className="num">×{shipMode ? item.itemNum : item.num}{item.unit ? (item.unit) : ''}</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
  }
}
