import React, { Component } from 'react'
import { noop } from 'wmkit'

export interface AlertProps {
  text: any,
  cb?: () => void,
  close?: () => void,
  time?: number
}

export default class Alert extends Component<AlertProps, any> {

  static defaultProps = {
    text: '内容...',
    time: 800,
    cb: noop,
    close: noop
  }

  componentDidMount() {
    const { close, cb, time } = this.props;
    setTimeout(function () {
      close();
      if (cb) {
        cb();
      }
    }, time);
  }

  render() {
    const { text } = this.props;
    return (
      <div className="alert-message">
        {text}
      </div>
    )
  }
}
