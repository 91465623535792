import React, {Component} from "react";
import noop from "wmkit/noop";

export default class RadioHook extends Component<any, any> {

  static defaultProps = {
    data: [],
    checked: '0',
    onCheck: noop
  }

  render() {
    const {data, checked, onCheck} = this.props
    return (
      <div>
        {
          data.size > 0 ? data.toJS().map((v) => {
            return (
              <div onClick={() => onCheck(v.id)} className="form-item">
                <span className="form-text">{v.name}</span>
                <i className={`iconfont icon-danxuan ${checked == v.id ? 'checked' : ''} `}></i>
              </div>
            )
          }) : null
        }
      </div>
    )
  }
}