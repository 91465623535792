import React, { Component } from 'react';
import { fromJS, is, List, Map } from 'immutable';
import { _, Button, NumberInput, WMImage, WMkit } from 'wmkit';
import { cache } from 'config';
import { MarketingLabel } from 'biz';
import {
  calculateSpeInfo,
  changeNum,
  changeSpecDetail,
  createImmutableData,
  immediateBuy,
  purchase,
  returnStockFlag,
  rushToBuyingFlashSaleGoodsInfo
} from './state-change';

const SubmitButton = Button.Submit;
const styles = require('../css/style.css');

/**
 * 零售销售类型的商品-规格选择弹框
 */
export default class WMRetailChoose extends Component<any, any> {
  props: {
    data: any;
    visible: boolean;
    changeSpecVisible: Function;
    dataCallBack?: Function;
    //秒杀商品对象
    flashsaleGoods?: any;
    //是否是秒杀商品
    flashsaleGoodsFlag?: boolean;
    iepInfo: any
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillReceiveProps(nextProps) {
    const goods = nextProps.data || {};
    const thisGoods = this.props.data || {};
    if (this.state.goodsInfo) {
      const flashsaleGoods = nextProps.flashsaleGoods || {};
      const flashsaleGoodsFlag = nextProps.flashsaleGoodsFlag;
      if (flashsaleGoodsFlag) {
        this._changeNum(
          flashsaleGoods.get('minNum'),
          flashsaleGoods.get('stock'),
          flashsaleGoods.get('goodsInfoId')
        );
      }
    }
    if (
      !is(fromJS(goods), fromJS(thisGoods)) &&
      (nextProps.data && nextProps.data.skuId)
    ) {
      // 组装层级结构的规格数据
      const dataIm = createImmutableData(nextProps.data);
      // 主要是计算每个规格值是否灰化不可点击, 以及计算得出当前的sku
      this.setState(
        {
          ...dataIm,
          ...calculateSpeInfo(dataIm)
        },
        () => {
          const flashsaleGoods = nextProps.flashsaleGoods || {};
          const flashsaleGoodsFlag = nextProps.flashsaleGoodsFlag;
          if (flashsaleGoodsFlag) {
            this._changeNum(
              flashsaleGoods.get('minNum'),
              flashsaleGoods.get('stock'),
              flashsaleGoods.get('goodsInfoId')
            );
          }
        }
      );
    }
  }

  render() {
    const {
      visible,
      changeSpecVisible,
      flashsaleGoods,
      flashsaleGoodsFlag
    } = this.props;
    const {
      goods = Map(),
      goodsInfo = Map(),
      goodsInfoCache = Map(), //缓存之前选择的sku,防止用户取消选中规格时,无信息展示的问题
      goodsSpecs = List()
    } = this.state;
    let noSpecStockFlag = returnStockFlag(
      goodsInfo.get('stock'),
      goodsInfo.get('count')
    );
    let goodsStock = goodsInfo.get('stock');
    if (flashsaleGoodsFlag) {
      noSpecStockFlag = false;
      goodsStock = flashsaleGoods.get('stock');
    }
    // 划线价
    const lineShowPrice = this._originPriceInfo(
      goods.get('linePrice'),
      goodsInfo,
      goodsInfoCache
    );

    // 社交电商相关内容显示与否
    const social = goodsInfo.get('distributionGoodsAudit') == 2 ? true : false;
    const isDistributor = WMkit.isShowDistributionButton();

    return (
      <div style={{ display: !visible || !goods ? 'none' : 'block' }}>
        <div
          className="choose-mask"
          onClick={() => {
            changeSpecVisible(false);
          }}
        />
        <div className="choose-body">
          <div className="choose-content">
            {/*弹窗关闭按钮*/}
            <div
              className="close"
              onClick={() => {
                changeSpecVisible(false);
              }}
            >
              <i className="iconfont icon-guanbi" />
            </div>

            {/*sku图文信息*/}
            <div className="choose-top b-1px-b">
              <WMImage
                mode="pad"
                src={goodsInfoCache.get('goodsInfoImg')}
                alt=""
                width="100"
                height="100"
              />
              <div className="title-box">
                <p className="title">{goods ? goods.get('goodsName') : ' '}</p>
                {/*价格*/}
                {this._price()}

                {!social &&
                  !isDistributor &&
                  !flashsaleGoodsFlag && (
                    <div className="level-box">
                      <MarketingLabel
                        marketingLabels={
                          goodsInfo.get('marketingLabels') ||
                          goodsInfoCache.get('marketingLabels')
                        }
                        couponLabels={
                          goodsInfo.get('couponLabels') ||
                          goodsInfoCache.get('couponLabels')
                        }
                      />
                    </div>
                  )}
              </div>
            </div>

            {/*sku中间滚动区域*/}
            <div className="choose-center">
              {/*sku选择规格*/}
              {goodsSpecs &&
                goodsSpecs.size > 0 &&
                goodsSpecs.toJS().map((spec, index) => {
                  return (
                    <div className="spec-box" key={spec.specId}>
                      <label className="spec-title">{spec.specName}</label>
                      <div className="spec-item">
                        {spec.specDetails.map((det) => {
                          return (
                            <span
                              key={det.specDetailId}
                              onClick={
                                spec.defaultVal != det.specDetailId &&
                                  det.disabled
                                  ? () => { }
                                  : () => {
                                    this._changeSpecDetail(
                                      spec.defaultVal == det.specDetailId
                                        ? null
                                        : det.specDetailId,
                                      index
                                    );
                                  }
                              }
                              className={
                                spec.defaultVal == det.specDetailId
                                  ? 'spec-items checked'
                                  : det.disabled
                                    ? 'spec-items invalid'
                                    : 'spec-items'
                              }
                            >
                              {det.detailName}
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}

              {/*sku选择数量*/}
              <div className="sku-num retail-num">
                <div className="sku-bottom">
                  <label className="spec-title">数量</label>
                  <div className="row-flex">
                    <span className="other-text">
                      {!social &&
                        !flashsaleGoodsFlag && (
                          <span>
                            {goodsInfo.get('count') &&
                              goodsInfo.get('count') + '起订'}
                            {goodsInfo.get('maxCount') &&
                              '，' + '限订' + goodsInfo.get('maxCount')}
                            &nbsp;&nbsp;
                          </span>
                        )}
                      {this._stock()}
                      {goods.get('goodsUnit')}&nbsp;&nbsp;
                    </span>
                    <NumberInput
                      disableNumberInput={noSpecStockFlag}
                      value={noSpecStockFlag ? 0 : goodsInfo.get('num')}
                      max={this._numberInputMax()}
                      onDelayChange={(value) =>
                        this._changeNum(
                          value,
                          goodsStock,
                          goodsInfo.get('goodsInfoId')
                        )
                      }
                      min={this._numberInputMin()}
                      error={goodsInfo.get('error')}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/*sku底部加入购物车*/}
            {flashsaleGoodsFlag ? (
              <div className="bottom b-1px-t">
                <span className="normal-text">
                  已选{goodsInfo.get('num') || 0}
                  {goods.get('goodsUnit')}
                </span>
                <SubmitButton
                  defaultStyle={{
                    backgroundColor: '#FF0000',
                    borderColor: '#FF0000'
                  }}
                  disabled={
                    !flashsaleGoods.get('stock') || !goodsInfo.get('num')
                  }
                  text="立即抢购"
                  onClick={() =>
                    this._rushToBuyingFlashSaleGoodsInfo(
                      flashsaleGoods.get('id'),
                      goodsInfo.get('num')
                    )
                  }
                />
              </div>
            ) : social && isDistributor ? (
              <div className="social-bottom b-1px-t">
                <SubmitButton
                  defaultStyle={{
                    backgroundColor: '#000',
                    borderColor: '#000'
                  }}
                  disabled={!goodsInfo.get('num')}
                  text="加入购物车"
                  onClick={() => this._purchase()}
                />
                {/* <SubmitButton
                  disabled={!goodsInfo.get('num')}
                  text="立即购买"
                  onClick={() => {}}
                /> */}
              </div>
            ) : (
                  <div className="bottom b-1px-t">
                    <span className="normal-text">
                      已选{goodsInfo.get('num') || 0}
                      {goods.get('goodsUnit')}
                    </span>
                    <SubmitButton
                      disabled={!goodsInfo.get('num')}
                      text="加入购物车"
                      onClick={() => this._purchase()}
                    />
                  </div>
                )}
          </div>
        </div>
      </div>
    );
  }

  /**
   * 获取是否展示划线价,以及划线价
   *   a.若划线价存在,则展示
   *   b.若划线价不存在
   *     b.1.登录前,不展示
   *     b.2.登陆后,展示sku市场价
   * @private
   */
  _originPriceInfo = (linePrice, goodsInfoIm, goodsInfoCache) => {
    if (linePrice) {
      return linePrice;
    } else {
      if (WMkit.isLoginOrNotOpen()) {
        return (
          goodsInfoIm.get('marketPrice') || goodsInfoCache.get('marketPrice')
        );
      } else {
        return null;
      }
    }
  };

  /**
   * 切换选中前n-1个规格项的规格值
   * @param specDetailId
   * @param index
   * @private
   */
  _changeSpecDetail = (specDetailId, index) => {
    this.setState(changeSpecDetail(this.state, specDetailId, index), () => {
      if (this.props.dataCallBack) {
        this.props.dataCallBack(this.state.goodsInfo);
      }
    });
  };

  /**
   * 用户改变sku购买数量
   * @param num 数量
   * @param stock 库存
   * @param goodsInfoId sku标识
   * @private
   */
  _changeNum = (savedNum, stock, goodsInfoId) => {
    savedNum = stock > 0 ? (savedNum < stock ? savedNum : stock) : 0;
    this.setState(changeNum(this.state, { num: savedNum, goodsInfoId }));
  };

  /**
   * 加入购物车
   * @private
   */
  _purchase = async () => {
    await purchase(this.state);
    this.props.changeSpecVisible(false);
  };

  /**
   * 立即购买
   */
  _immediateBuy = async () => {
    await immediateBuy(this.state);
    this.props.changeSpecVisible(false);
  };

  /**
   * 库存数
   */
  _stock = () => {
    const { flashsaleGoods, flashsaleGoodsFlag } = this.props;
    const { goodsInfo = Map() } = this.state;

    let stock = 0;
    if (flashsaleGoodsFlag) {
      stock = flashsaleGoods.get('stock');
    } else {
      stock = goodsInfo.get('stock') || 0;
    }
    return '库存' + stock;
  };

  /**
   * 起售量
   */
  _numberInputMin = () => {
    const { flashsaleGoods, flashsaleGoodsFlag } = this.props;
    let min = 0;
    if (flashsaleGoodsFlag) {
      min = flashsaleGoods.get('minNum');
    } else {
      min = 1;
    }
    return min;
  };

  /**
   * 限售量
   */
  _numberInputMax = () => {
    const { goodsInfo = Map() } = this.state;
    const { flashsaleGoods, flashsaleGoodsFlag } = this.props;
    let max = 0;
    if (flashsaleGoodsFlag) {
      const maxNum = flashsaleGoods.get('maxNum');
      const stock = flashsaleGoods.get('stock');
      max = stock > maxNum ? maxNum : stock;
    } else {
      max = goodsInfo.get('stock') || 0;
    }
    return max;
  };

  //价格
  _price = () => {
    const {
      goods = Map(),
      goodsInfo = Map(),
      goodsInfoCache = Map(), //缓存之前选择的sku,防止用户取消选中规格时,无信息展示的问题,
    } = this.state;

    const { flashsaleGoods, flashsaleGoodsFlag, iepInfo = Map() } = this.props;

    const social = goodsInfo.get('distributionGoodsAudit') == 2 ? true : false;
    const isDistributor = WMkit.isShowDistributionButton();

    const flashsalePrice = flashsaleGoodsFlag && flashsaleGoods.get('price');
    // iep属性
    const { isIepAuth: iepSwitch, iepInfo: info = {} } = iepInfo.toJS();
    // 默认为企业价
    const { enterprisePriceName } = info;
    //企业购商品的判断
    const iepGoodsShowFlag = iepSwitch && goodsInfo.get('enterPriseAuditState') == 2 && !flashsaleGoodsFlag;

    // 划线价
    const lineShowPrice = !iepGoodsShowFlag && this._originPriceInfo(
      goods.get('linePrice'),
      goodsInfo,
      goodsInfoCache
    );

    const html = (
      <p className="price">
        ¥
        {flashsalePrice
          ? _.addZero(flashsalePrice)
          : social
            ? _.addZero(goodsInfo.get('marketPrice'))
            : _.addZero(
              goodsInfo.get('salePrice') || goodsInfoCache.get('salePrice')
            )}
         {iepGoodsShowFlag && (
          <span className="qyg-price-box">&nbsp;&nbsp;
            ￥{_.addZero(goodsInfo.get('enterPrisePrice'))}
            <span className="enter-price-cart">{enterprisePriceName}</span>
          </span>
        )}
        {!!lineShowPrice && !iepGoodsShowFlag && (
          <span className="delPrice">¥{_.addZero(lineShowPrice)}</span>
        )}
        {social &&
          !flashsaleGoodsFlag &&
          isDistributor &&
          !sessionStorage.getItem(cache.INVITEE_ID) && (
            <span className="commission">
              /&nbsp;赚{_.addZero(goodsInfo.get('distributionCommission'))}
            </span>
          )}
      </p>
    );

    return html;
  };

  _rushToBuyingFlashSaleGoodsInfo = async (flashSaleGoodsId, num) => {
    await rushToBuyingFlashSaleGoodsInfo(flashSaleGoodsId, num);
  };
}
