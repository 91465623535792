import { Fetch } from 'wmkit';
import { stringify } from 'querystring';
import { sendCode } from '../../../../src/login/webapi';

type TResult = { code: string; message: string; context: any };

/**
 * 立即购买
 */
export const immediateBuy = (buyGoodsInfo, openGroupon, grouponNo) => {
  let params = {
    goodsInfoId: buyGoodsInfo.get('goodsInfoId'),
    buyCount: buyGoodsInfo.get('num'),
    openGroupon: openGroupon
  };
  return Fetch<TResult>('/trade/groupon-buy', {
    method: 'POST',
    body: grouponNo
      ? JSON.stringify({
          ...params,
          grouponNo: grouponNo
        })
      : JSON.stringify(params)
  });
};

export const immediateBuyValidate = (buyGoodsInfo, openGroupon, grouponNo) => {
  let params = {
    goodsInfoId: buyGoodsInfo.get('goodsInfoId'),
    buyCount: buyGoodsInfo.get('num'),
    openGroupon: openGroupon
  };
  return Fetch<TResult>('/groupon/vaildateGrouponStatusForOpenOrJoin', {
    method: 'POST',
    body: grouponNo
      ? JSON.stringify({
          ...params,
          grouponNo: grouponNo
        })
      : JSON.stringify(params)
  });
};
