/**
 * 路由配置文件
 *   hasBottom     是否有底部导航栏
 *   withoutLogin  true:完全不需要登陆的页面(如登录,注册,修改密码等页面)
 *   openAccess    true:需要根据"是否需要登陆"的开关,来判定该页面是否需要登陆
 */
import { WMkit } from 'wmkit';

const errorRouter = {
  // 不配置path，什么都匹配不到的情况匹配这个，要放最后
  path: '',
  title: document.title || '找不到页面',
  exact: true,
  asyncComponent: () => import('./error'),
  withoutLogin: true
};
const routers = [
  {
    path: '/',
    title: document.title || '首页',
    exact: true,
    asyncComponent: () =>
      WMkit.isShop() ? import('./social-c/shop-index-c') : import('./main'),
    hasBottom: true,
    openAccess: true
  },
  {
    path: '/test',
    exact: true,
    withoutLogin: true,
    asyncComponent: () => import('./pages/test'),
    hasBottom: true
  },
  {
    path: '/page/:pageType/:pageCode/:storeId?',
    title: document.title,
    exact: true,
    asyncComponent: () => import('./x-site'),
    openAccess: true
  },
  {
    path: '/register/:employeeId?',
    title: document.title || '注册',
    exact: true,
    asyncComponent: () => import('./register'),
    withoutLogin: true
  },
  {
    path: '/iep-register',
    title: document.title || '企业会员注册',
    exact: true,
    asyncComponent: () => import('./iep-vas/iep-register'),
    withoutLogin: true
  },
  {
    path: '/improve-information/:cid',
    title: document.title || '完善账户信息',
    exact: true,
    asyncComponent: () => import('./improve-information'),
    withoutLogin: true
  },

  {
    path: '/improve-iep-info/:cid',
    title: document.title || '完善账户信息',
    exact: true,
    asyncComponent: () => import('./iep-vas/improve-iep-info'),
    withoutLogin: true
  },
  {
    path: '/purchase-order',
    title: document.title || '购物车',
    exact: true,
    asyncComponent: () => import('./purchase-order'),
    hasBottom: true,
    openAccess: true
  },
  {
    path: '/register-agreement',
    title: document.title || '注册协议',
    exact: true,
    asyncComponent: () => import('./register-agreement'),
    withoutLogin: true
  },
  {
    path: '/iep-register-agreement',
    title: document.title || '企业会员注册协议',
    exact: true,
    asyncComponent: () => import('./iep-vas/iep-register-agreement'),
    withoutLogin: true
  },
  {
    path: '/iep-register-info',
    title: document.title || '完善公司信息',
    exact: true,
    asyncComponent: () => import('./iep-vas/iep-register-info'),
    withoutLogin: true
  },
  {
    path: '/sign',
    title: document.title || '签到',
    exact: true,
    asyncComponent: () => import('./pages/sign')
  },
  {
    path: '/push-center',
    title: document.title || '消息',
    exact: true,
    asyncComponent: () => import('./pages/message-push/center')
  },
  {
    path: '/push-list/:type',
    title: document.title || '服务通知',
    exact: true,
    asyncComponent: () => import('./pages/message-push/list')
  },
  // //客服咨询
  // {
  //   path: '/consultation',
  //   title: document.title || '客服咨询',
  //   exact: true,
  //   asyncComponent: () => import('./pages/consultation'),
  //   withoutLogin: true
  // },
  {
    path: '/goods-detail/:id',
    title: document.title || '商品详情',
    exact: true,
    asyncComponent: () => import('./goods-detail'),
    openAccess: true
  },
  {
    path: '/login',
    title: document.title || '登录',
    exact: true,
    asyncComponent: () => import('./login'),
    withoutLogin: true
  },
  {
    path: '/returnsFirst/:tid',
    title: document.title || '申请退货退款',
    exact: true,
    asyncComponent: () => import('./return-refund/return-first-step')
  },
  {
    path: '/goodsList',
    title: document.title || '商品列表',
    exact: true,
    asyncComponent: () => import('./goods-list'),
    hasBottom: true,
    openAccess: true
  },
  {
    path: '/goods-list-promotion/:mid',
    title: document.title || '促销列表',
    exact: true,
    asyncComponent: () => import('./goods-list-promotion'),
    hasBottom: false,
    openAccess: true
  },
  {
    path: '/order-confirm',
    title: document.title || '确认订单',
    exact: true,
    asyncComponent: () => import('./order-confirm')
  },
  {
    path: '/points-order-confirm',
    title: document.title || '确认西兰豆订单',
    exact: true,
    asyncComponent: () => import('./points-order-confirm')
  },
  {
    path: '/order-list',
    title: document.title || '订单列表',
    exact: true,
    asyncComponent: () => import('./order-list'),
    hasBottom: true
  },
  {
    path: '/customer-order-detail/:tid',
    title: document.title || '客户订单详情',
    exact: true,
    asyncComponent: () => import('./customer-order-detail'),
    hasBottom: true
  },
  {
    path: '/customer-order-list',
    title: document.title || '推广订单',
    exact: true,
    asyncComponent: () => import('./customer-order-list'),
    hasBottom: true
  },
  {
    path: '/returnsSecond',
    title: document.title || '申请退货退款',
    exact: true,
    asyncComponent: () => import('./return-refund/return-second-step')
  },
  {
    path: '/applySuccess/:rid',
    title: document.title || '退货退款申请提交成功',
    exact: true,
    asyncComponent: () => import('./return-refund/return-refund-success')
  },
  {
    path: '/user-center',
    title: document.title || '我的',
    exact: true,
    asyncComponent: () => import('./user-center'),
    hasBottom: true,
    openAccess: true
  },
  {
    path: '/member-center',
    title: document.title || '会员中心',
    exact: true,
    asyncComponent: () => import('./user/member-center'),
    openAccess: true
  },
  {
    path: '/growth-value',
    title: document.title || '会员中心',
    exact: true,
    asyncComponent: () => import('./user/growth-value'),
    openAccess: true
  },
  {
    path: '/settings',
    title: document.title || '设置',
    exact: true,
    asyncComponent: () => import('./settings'),
    hasBottom: false
  },
  {
    path: '/user-info',
    title: document.title || '基本信息',
    exact: true,
    asyncComponent: () => import('./user-info')
  },
  {
    path: '/user-finance',
    title: document.title || '财务信息',
    exact: true,
    asyncComponent: () => import('./user-finance')
  },
  {
    path: '/user-account',
    title: document.title || '银行账户',
    exact: true,
    asyncComponent: () => import('./user-account')
  },
  {
    path: '/user-account-edit/:accountId',
    title: document.title || '银行账户编辑',
    exact: true,
    asyncComponent: () => import('./user-account-edit')
  },
  {
    path: '/user-invoice',
    title: document.title || '增票资质',
    exact: true,
    asyncComponent: () => import('./user-invoice')
  },
  {
    path: '/user-email',
    title: document.title || '财务邮箱',
    exact: true,
    asyncComponent: () => import('./user-email')
  },
  {
    path: '/user-safe',
    title: document.title || '账号安全',
    exact: true,
    asyncComponent: () => import('./user-safe')
  },
  {
    path: '/user-safe-password',
    title: document.title || '修改密码',
    exact: true,
    asyncComponent: () => import('./user-safe-password'),
    withoutLogin: true
  },
  {
    path: '/user-safe-mobile',
    title: document.title || '修改绑定手机号',
    exact: true,
    asyncComponent: () => import('./user-safe-mobile')
  },
  {
    path: '/user-safe-mobile-next',
    title: document.title || '修改绑定手机号',
    exact: true,
    asyncComponent: () => import('./user-safe-mobile-next')
  },
  {
    path: '/balance-pay-password',
    title: document.title || '设置支付密码',
    exact: true,
    asyncComponent: () => import('./balance-pay-password')
  },
  {
    path: '/balance-pay-password-next',
    title: document.title || '设置支付密码',
    exact: true,
    asyncComponent: () => import('./balance-pay-password-next')
  },
  {
    path: '/applyRefund/:tid',
    title: document.title || '申请退货退款',
    exact: true,
    asyncComponent: () => import('./return-refund/refund-first-step')
  },
  {
    path: '/main',
    title: document.title || '首页',
    exact: true,
    asyncComponent: () => import('./main'),
    openAccess: true
  },
  {
    path: '/user-collection',
    title: document.title || '我的收藏',
    exact: true,
    asyncComponent: () => import('./user-collection')
  },
  {
    path: '/search',
    title: document.title || '搜索页',
    exact: true,
    asyncComponent: () => import('./search'),
    openAccess: true
  },
  {
    path: '/order-sku-list/:sid',
    title: document.title || '确认订单-商品清单',
    exact: true,
    asyncComponent: () => import('./order-sku-list')
  },
  {
    path: '/points-order-sku-list',
    title: document.title || '确认西兰豆订单-商品清单',
    exact: true,
    asyncComponent: () => import('./points-order-sku-list')
  },
  {
    path: '/receive-address',
    title: document.title || '收货地址列表',
    exact: true,
    asyncComponent: () => import('./receive-address')
  },
  {
    path: '/receive-address-edit/:addressId',
    title: document.title,
    exact: true,
    asyncComponent: () => import('./receive-address-edit')
  },
  {
    path: '/logistics-input/:rid',
    title: document.title || '填写物流信息',
    exact: true,
    asyncComponent: () => import('./logistics-input')
  },
  {
    path: '/order-invoice/:cid',
    title: document.title || '确认订单-索取发票',
    exact: true,
    asyncComponent: () => import('./order-invoice')
  },
  {
    path: '/logistics-select',
    title: document.title || '物流公司选择',
    exact: true,
    asyncComponent: () => import('./logistics-select')
  },
  {
    path: '/refund-list',
    title: document.title || '退货退款列表',
    exact: true,
    asyncComponent: () => import('./refund-list')
  },
  {
    path: '/return-detail/:rid',
    title: document.title || '退货退款详情',
    exact: true,
    asyncComponent: () => import('./return-detail')
  },
  {
    path: '/finance-refund-record/:rid',
    title: document.title || '退款记录',
    exact: true,
    asyncComponent: () => import('./finance-refund-record')
  },
  {
    path: '/order-detail/:tid',
    title: document.title || '订单详情',
    exact: true,
    asyncComponent: () => import('./order-detail')
  },
  {
    path: '/append-list',
    title: document.title || '订单附件',
    exact: true,
    asyncComponent: () => import('./append-list')
  },
  {
    path: '/user-integral',
    title: document.title || '我的西兰豆',
    exact: true,
    asyncComponent: () => import('./user-integral')
  },
  {
    path: '/order-confirm-success',
    title: document.title || '订单提交成功',
    exact: true,
    asyncComponent: () => import('./order-confirm-success')
  },
  {
    path: '/points-order-confirm-success',
    title: document.title || '西兰豆订单提交成功',
    exact: true,
    asyncComponent: () => import('./points-order-confirm-success')
  },
  {
    path: '/pay-detail/:tid',
    title: document.title || '付款记录',
    exact: true,
    asyncComponent: () => import('./pay-detail')
  },
  {
    path: '/goods-cate',
    title: document.title || '商品分类',
    exact: true,
    asyncComponent: () => import('./goods-cate'),
    hasBottom: true,
    openAccess: true
  },
  {
    path: '/fill-payment/:tid',
    title: document.title || '填写付款单',
    exact: true,
    asyncComponent: () => import('./fill-payment')
  },
  {
    path: '/compose-pay-online',
    title: document.title || '合并支付',
    exact: true,
    asyncComponent: () => import('./pay-online')
  },
  {
    path: '/pay-online/:tid?',
    title: document.title || '线上支付',
    exact: true,
    asyncComponent: () => import('./pay-online')
  },
  {
    path: '/pay-success/:tid/:parentTid?',
    title: document.title || '订单支付成功',
    exact: true,
    asyncComponent: () => import('./pay-online/pay-sucess')
  },
  {
    path: '/ship-record/:tid/:type',
    title: document.title || '发货记录',
    exact: true,
    asyncComponent: () => import('./ship-record')
  },
  {
    path: '/ship-list/:oid/:tid',
    title: document.title || '发货商品清单',
    exact: true,
    asyncComponent: () => import('./ship-list')
  },
  {
    path: '/logistics-info/:oid/:tid/:type',
    title: document.title || '物流信息',
    exact: true,
    asyncComponent: () => import('./logistics-info')
  },
  {
    path: '/return-logistics-info/:rid',
    title: document.title || '退货物流信息',
    exact: true,
    asyncComponent: () => import('./return-logistics-info')
  },
  {
    path: '/invoice-info/:tid/:type',
    title: document.title || '发票信息',
    exact: true,
    asyncComponent: () => import('./invoice-info')
  },
  {
    path: '/loading',
    title: document.title || '加载中',
    exact: true,
    asyncComponent: () => import('./loading'),
    withoutLogin: true
  },
  {
    path: '/usersafe-password-next',
    title: document.title || '修改密码第二步',
    exact: true,
    asyncComponent: () => import('./usersafe-password-next'),
    withoutLogin: true
  },
  {
    path: '/goods-failure',
    title: document.title || '商品详情',
    exact: true,
    asyncComponent: () => import('./goods-failure'),
    openAccess: true
  },
  {
    path: '/net-trouble',
    title: document.title || '网络故障',
    exact: true,
    asyncComponent: () => import('./net-trouble'),
    withoutLogin: true
  },
  {
    path: '/seller-account',
    title: document.title || '卖家收款账号',
    exact: true,
    asyncComponent: () => import('./seller-account')
  },
  {
    path: '/fill-payment-success/:tid',
    title: document.title || '付款单提交成功',
    exact: true,
    asyncComponent: () => import('./fill-payment/fill-payment-success')
  },
  {
    path: '/return-sku-list/:rid',
    title: document.title || '退单商品列表',
    exact: true,
    asyncComponent: () => import('./return-sku-list')
  },
  {
    path: '/order-detail-skus/:tid',
    title: document.title || '订单详情-商品清单',
    exact: true,
    asyncComponent: () => import('./order-detail/order-detail-skus')
  },
  {
    path: '/points-order-detail-skus/:tid',
    title: document.title || '西兰豆订单详情-商品清单',
    exact: true,
    asyncComponent: () =>
      import('./points-order-detail/points-order-detail-skus')
  },
  {
    path: '/distribute-order-detail-skus/:tid',
    title: document.title || '客户订单详情-商品清单',
    exact: true,
    asyncComponent: () => import('./customer-order-detail/order-detail-skus')
  },

  {
    path: '/store-main/:sid',
    title: document.title || '店铺首页',
    exact: true,
    asyncComponent: () => import('./store-main'),
    openAccess: true
  },
  {
    path: '/store-list',
    title: document.title || '店铺列表',
    exact: true,
    asyncComponent: () => import('./store-list'),
    openAccess: true
  },
  {
    path: '/store-goods-search/:sid',
    title: document.title || '店铺搜索',
    exact: true,
    asyncComponent: () => import('./shop-search'),
    openAccess: true
  },
  {
    path: '/store-goods-cates/:sid',
    title: document.title || '店铺分类',
    exact: true,
    asyncComponent: () => import('./shop-goods-cate'),
    openAccess: true
  },
  {
    path: '/store-goods-list/:sid',
    title: document.title || '店铺商品',
    exact: true,
    asyncComponent: () => import('./shop-goods-list'),
    openAccess: true
  },
  {
    path: '/store-profile/:sid',
    title: document.title || '店铺档案',
    exact: true,
    asyncComponent: () => import('./store-profile'),
    openAccess: true
  },
  {
    path: '/payment-delivery',
    title: document.title || '支付配送',
    exact: true,
    asyncComponent: () => import('./payment-delivery')
  },
  {
    path: '/member-shop/:sid',
    title: document.title || '店铺会员',
    exact: true,
    asyncComponent: () => import('./member-shop'),
    openAccess: true
  },
  {
    path: '/store-attention',
    title: document.title || '关注店铺',
    exact: true,
    asyncComponent: () => import('./store-attention')
  },
  {
    path: '/error',
    title: document.title || '找不到页面',
    exact: true,
    asyncComponent: () => import('./error'),
    withoutLogin: true
  },
  {
    path: '/chose-service/:sid',
    title: document.title || '在线客服',
    exact: true,
    asyncComponent: () => import('./chose-service')
  },
  {
    path: '/linked-account',
    title: document.title || '关联账号',
    exact: true,
    asyncComponent: () => import('./linked-account')
  },
  {
    path: '/wechat-login',
    title: document.title || '微信登录',
    exact: true,
    asyncComponent: () => import('./wechat-login'),
    withoutLogin: true
  },
  {
    path: '/my-coupon',
    title: document.title || '我的优惠券',
    exact: true,
    asyncComponent: () => import('./my-coupon')
  },
  {
    path: '/use-coupon',
    title: document.title || '使用优惠券',
    exact: true,
    asyncComponent: () => import('./use-coupon')
  },
  {
    path: '/coupon-center',
    title: document.title || '领券中心',
    exact: true,
    asyncComponent: () => import('./coupon-center'),
    openAccess: true
  },
  {
    path: '/coupon-promotion',
    title: document.title || '优惠券凑单页',
    exact: true,
    asyncComponent: () => import('./coupon-promotion')
  },
  {
    path: '/about-us',
    title: document.title || '关于我们',
    exact: true,
    asyncComponent: () => import('./about-us')
  },
  {
    path: '/download',
    title: document.title || 'app下载',
    exact: true,
    asyncComponent: () => import('./download'),
    withoutLogin: true
  },
  {
    path: '/class-equity/:id',
    title: document.title || '等级权益',
    exact: true,
    asyncComponent: () => import('./user/class-equity'),
    withoutLogin: true
  },
  {
    path: '/evaluation-list/:goodsId',
    title: document.title || '更多评价',
    exact: true,
    asyncComponent: () => import('./evaluation-list'),
    withoutLogin: true
  },
  {
    path: '/invite-friends',
    title: document.title || '邀请朋友',
    exact: true,
    asyncComponent: () => import('./invite-friends'),
    withoutLogin: false
  },
  {
    path: '/balance/home',
    title: document.title || '余额',
    exact: true,
    asyncComponent: () => import('./balance/home')
  },
  {
    path: '/balance/deposit',
    title: document.title || '提现',
    exact: true,
    asyncComponent: () => import('./balance/deposit')
  },
  {
    path: '/balance/applicat-form',
    title: document.title || '提现申请单',
    exact: true,
    asyncComponent: () => import('./balance/applicat-form')
  },
  {
    path: '/balance/successful/:drawCashId',
    title: document.title || '提现提交成功',
    exact: true,
    asyncComponent: () => import('./balance/successful')
  },
  {
    path: '/balance/cash-record',
    title: document.title || '提现记录',
    exact: true,
    asyncComponent: () => import('./balance/cash-record')
  },
  {
    path: '/balance/cash-detail/:dcId',
    title: document.title || '提现详情',
    exact: true,
    asyncComponent: () => import('./balance/cash-detail')
  },
  {
    path: '/balance/account-detail',
    title: document.title || '账户明细',
    exact: true,
    asyncComponent: () => import('./balance/account-detail')
  },
  {
    path: '/sales-perform',
    title: document.title || '我的销售业绩',
    exact: true,
    asyncComponent: () => import('./sales/sales-perform'),
    withoutLogin: true
  },
  {
    path: '/sales-rank',
    title: document.title || '销售排行',
    exact: true,
    asyncComponent: () => import('./sales/sales-rank'),
    withoutLogin: true
  },
  {
    path: '/shop-index',
    title: document.title || '店铺管理',
    exact: true,
    asyncComponent: () => import('./shop/shop-index'),
    withoutLogin: true
  },
  {
    path: '/shop-edit',
    title: document.title || '店铺精选',
    exact: true,
    asyncComponent: () => import('./shop/shop-edit'),
    withoutLogin: true
  },
  {
    path: '/shop-goods',
    title: document.title || '店铺选品',
    exact: true,
    asyncComponent: () => import('./shop/shop-goods'),
    withoutLogin: true
  },
  {
    path: '/social-c/my-customer/:id?',
    title: document.title || '我的用户',
    exact: true,
    asyncComponent: () => import('./social-c/my-customer'),
    withoutLogin: false
  },
  {
    path: '/graphic-material/:mid',
    title: document.title || '发圈素材',
    exact: true,
    asyncComponent: () => import('./graphic-material'),
    openAccess: true
  },
  {
    path: '/store-bags',
    title: document.title || '开店礼包',
    exact: true,
    asyncComponent: () => import('./social-c/store-bags'),
    openAccess: true
  },
  {
    path: '/register-c/:employeeId?/:inviteeId',
    title: document.title || '注册',
    exact: true,
    asyncComponent: () => import('./social-c/register'),
    withoutLogin: true
  },
  {
    path: '/shop-index-c/:inviteeId?',
    title: document.title || '店铺精选',
    exact: true,
    asyncComponent: () => import('./social-c/shop-index-c'),
    hasBottom: false,
    withoutLogin: true
  },
  {
    path: '/shop-goods-list-c',
    title: document.title || '店铺精选',
    exact: true,
    asyncComponent: () => import('./social-c/goods-list-c'),
    withoutLogin: true
  },
  {
    path: '/search-c',
    title: document.title || '搜索页',
    exact: true,
    asyncComponent: () => import('./social-c/search-c'),
    openAccess: true
  },
  {
    path: '/store-bags-goods-detail/:id',
    title: document.title || '商品详情',
    exact: true,
    asyncComponent: () => import('./social-c/store-bags-goods-detail')
  },
  //从店铺精选点击进入的商品详情（都是分销商品）
  {
    path: '/shop-index/goods-detail/:id/:goodsId/:skuId',
    title: document.title || '商品详情',
    exact: true,
    asyncComponent: () => import('./social-c/goods-detail'),
    openAccess: true
  },
  {
    path: '/spellgroup-detail/:id',
    title: document.title || '拼团详情',
    exact: true,
    asyncComponent: () => import('./spellgroup-detail'),
    openAccess: true
  },
  {
    path: '/group-buy-detail/:gid',
    title: document.title || '参团详情',
    exact: true,
    asyncComponent: () => import('./group-buy-detail'),
    openAccess: true
  },
  {
    path: '/group-order-list',
    title: document.title || '我的拼购',
    exact: true,
    asyncComponent: () => import('./group-order-list'),
    openAccess: true
  },
  {
    path: '/group-goods-list',
    title: document.title || '拼团',
    exact: true,
    asyncComponent: () => import('./group-goods-list'),
    openAccess: true
  },
  {
    path: '/groupon-rule',
    title: document.title || '玩法介绍',
    exact: true,
    asyncComponent: () => import('./groupon-rule'),
    openAccess: true
  },
  {
    path: '/groupon-center',
    title: document.title || '拼购',
    exact: true,
    asyncComponent: () => import('./groupon-center'),
    openAccess: true
  },
  {
    path: '/groupon-selection',
    title: document.title || '热拼排行',
    exact: true,
    asyncComponent: () => import('./groupon-selection'),
    openAccess: true
  },
  {
    path: '/groupon-search-list',
    title: document.title || '拼购',
    exact: true,
    asyncComponent: () => import('./groupon-search-list'),
    openAccess: true
  },

  {
    path: '/evaluation-detail',
    title: document.title || '评价详情',
    exact: true,
    asyncComponent: () => import('./evaluation-detail'),
    withoutLogin: true
  },
  {
    path: '/evaluate/evaluate-center',
    title: document.title || '评价中心',
    exact: true,
    asyncComponent: () => import('./evaluate/evaluate-center'),
    withoutLogin: true
  },
  {
    path: '/evaluate/evaluate-drying',
    title: document.title || '评价晒单',
    exact: true,
    asyncComponent: () => import('./evaluate/evaluate-drying'),
    withoutLogin: true
  },
  {
    path: '/points-mall',
    title: document.title || '西兰豆商城',
    exact: true,
    asyncComponent: () => import('./points-mall'),
    withoutLogin: true
  },
  {
    path: '/flash-sale',
    title: document.title || '整点秒杀',
    exact: true,
    asyncComponent: () => import('./flash-sale'),
    withoutLogin: true
  },
  {
    path: '/not-develop',
    title: document.title || '敬请期待',
    exact: true,
    asyncComponent: () => import('./not-develop'),
    withoutLogin: true
  },
  {
    path: '/points-order-list',
    title: document.title || '西兰豆订单列表',
    exact: true,
    asyncComponent: () => import('./points-order-list'),
    hasBottom: true
  },
  {
    path: '/points-order-ship-record/:tid/:type',
    title: document.title || '西兰豆订单发货记录',
    exact: true,
    asyncComponent: () => import('./points-order-ship-record')
  },
  {
    path: '/points-order-detail/:tid',
    title: document.title || '西兰豆订单详情',
    exact: true,
    asyncComponent: () => import('./points-order-detail')
  },
  {
    path: '/distribution-center',
    title: document.title || '分销中心',
    exact: true,
    asyncComponent: () => import('./distribution-center'),
    hasBottom: true
  },
  {
    path: '/distribution-rule',
    title: document.title || '分销员规则介绍',
    exact: true,
    asyncComponent: () => import('./distribution-rule'),
    hasBottom: false
  },
  {
    path: '/reward-center',
    title: document.title || '奖励中心',
    exact: true,
    asyncComponent: () => import('./reward-center'),
    hasBottom: true
  },
  {
    path: '/distribution-register',
    title: document.title || '分销注册',
    exact: true,
    asyncComponent: () => import('./distribution-register'),
    withoutLogin: true
  },
  {
    path: '/material-circle',
    title: document.title || '发现',
    exact: true,
    asyncComponent: () => import('./material-circle')
  },
  {
    path: '/distribution-goods-list',
    title: document.title || '推广商品列表',
    exact: true,
    asyncComponent: () => import('./distribution-goods-list'),
    hasBottom: true
  },
  {
    path: '/flash-sale-goods-panic-buying',
    title: document.title || '秒杀活动商品抢购排队页面',
    exact: true,
    asyncComponent: () => import('./flash-sale-goods-panic-buying'),
    withoutLogin: true
  },
  {
    path: '/flash-sale-order-confirm',
    title: document.title || '秒杀商品确认订单',
    exact: true,
    asyncComponent: () => import('./flash-sale-order-confirm')
  },
  {
    path: '/flash-sale-order-invoice/:cid',
    title: document.title || '秒杀商品确认订单-索取发票',
    exact: true,
    asyncComponent: () => import('./flash-sale-order-invoice')
  },
  {
    path: '/iep-info',
    title: document.title || '公司信息',
    exact: true,
    asyncComponent: () => import('./iep-info')
  }
];
routers.push(errorRouter);
export { routers };
