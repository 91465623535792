import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Relax } from 'plume2'
import { noop } from 'wmkit'
@Relax
export default class AccountFrom extends Component<any, any> {
  props: {
    relaxProps?: {
      isShowpwd: boolean;
      buttonstate: boolean;
      buttonvalue: string;
      account: string;
      password: string;
      showPass: Function;
      doLogin: Function;
      setAccount: Function;
      setPassword: Function;
    };
  };

  static relaxProps = {
    isShowpwd: 'isShowpwd',
    buttonstate: 'buttonstate',
    buttonvalue: 'buttonvalue',
    account: 'account',
    password: 'password',
    showPass: noop,
    doLogin: noop,
    setAccount: noop,
    setPassword: noop
  };

  render() {
    let {
      isShowpwd,
      buttonstate,
      buttonvalue,
      account,
      password,
      showPass,
      doLogin,
      setPassword
    } = this.props.relaxProps;
    if (account.length > 11) {
      account = account.substring(0, 11);
    }
    return (
      <div style={{ marginTop: 25, width: '75%'}}>
        <div className="login-item">
          <div className="inputBox">
            <input
              className="formInput"
              type="tel"
              pattern="[0-9]*"
              placeholder="请输入您的手机号"
              value={account}              
              maxLength={11}
              onChange={e => this._getAccount(e)}
            />
          </div>
        </div>
        <div className="login-item">
          <div className="inputBox eyes-box">
            <input
              className="formInput"
              type={isShowpwd ? 'text' : 'password'}
              placeholder="密码"
              pattern="/^[A-Za-z0-9]{6,16}$/"
              value={password}
              onChange={e => setPassword(e.target.value)}
              maxLength={16}
            />
            <i
              onClick={() =>showPass()}
              className={`iconfont icon-${
                isShowpwd ? 'yanjing' : 'iconguanbiyanjing'
              }`}
            />
          </div>
        </div>
        <div className="l-findCode">
          <a href="/user-safe-password">忘记密码</a>
        </div>
        <div className="login-btnBox">
          <button
            className={buttonstate ? 'btn-disabled login-btn' : 'login-btn'}
            disabled={buttonstate}
            onClick={() =>doLogin()}
          >
            登录
          </button>
        </div>
      </div>
    );
  }

  _getAccount = (e) => {
    const { setAccount, account } = this.props.relaxProps;
    let value = e.target.value;
    //圆点.特殊处理
    let lastchart = value.substr(value.length - 1, 1);
    if (
      value.toString() % 1 != 0 ||
      lastchart == '.' ||
      lastchart == '+' ||
      lastchart == '-'
    ) {
      setAccount(account);
    } else {
      if (value.length > 11) {
        setAccount(account);
      } else {
        setAccount(value);
      }
    }
  };
}
