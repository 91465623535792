export const config = {
  // online
  BFF_HOST: 'https://mbff.s2b.wanmi.com',
  RENDER_HOST: 'https://app-render.s2b.wanmi.com',
  OSS_HOST: 'https://wanmi-x-site.oss-cn-shanghai.aliyuncs.com',
  PV_UV_HOST: 'http://perseus.s2b.wanmi.com',

  IMAGE_SERVER: __DEV__ ? '//img.1000.com/qm-a-img/prod/' : '',
  HTTP_TIME_OUT: 10,
  SUCCESS_CODE: 'K-000000'
};
